/* ------------------------------------------------------------------------------------------------ */
/* ------ camera selector */
.cameraselector {
    color: rgba(33, 33, 33, 1);
    font-size: 1em;
    width: 300px;
}
.cameraselector__selectbox {
    display: flex;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 1em;
    box-shadow: inset 0 0 2px rgba(33, 33, 33, 0.86);
    z-index: 10;
}
.cameraselector__title {
    display: flex;
    align-items: center;
    flex-basis: 86%;
    flex-grow: 8;
    flex-shrink: 8;
    padding: 5px 10px;
}
.cameraselector__title--empty {
    display: flex;
    align-items: center;
    flex-basis: 86%;
    flex-grow: 8;
    flex-shrink: 8;
    padding: 5px 10px;
    color: rgba(33, 33, 33, 0.5);
}
.cameraselector__ctrl--open,.cameraselector__ctrl--close {
    display: flex;
    flex-basis: 12%;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-content: center;
    color: rgba(33, 33, 33, 0.86);
    font-size: 30px;
    background-color: rgba(33, 33, 33, 0.08);
    border-radius: 0 1em 1em 0;
}
.cameraselector__optionbox--show {
    display: flex;
    max-height: 120px;
    background-color: white;
    margin: 5px 0 0 0;
    overflow-y: hidden;
}
.cameraselector__optionbox--hide {
    display: none;
}
.cameraselector__optionlist {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 100%;
    padding-left: 0;
    margin: 0;
    box-shadow: 2px 0px 2px 2px rgba(33, 33, 33, 0.12);
    overflow-y: auto;
}
.cameraselector__optionitem {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.cameraselector__optionitem:hover {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    background-color: rgba(33, 33, 33, .86);
    box-shadow: inset 0  0 2px rgba(255, 255, 255, 0.22);
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
    .cameraselector {
        width: auto;
    }
}
