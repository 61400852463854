.confirmation-button{
    align-items: center;
    position: fixed;
    right: 0px;
    padding: 20px;
    transition: width 1s;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    white-space: nowrap;
    z-index: 10;
    border: solid 1px white;
    border-right: none;
    transition: .1s all ease-in-out;
}

@media only screen and (max-width: 768px) {
    .confirmation-button{
        width: 100px;
        bottom: 50px;
        top: unset;
        margin-right: 0px;
    }
}
