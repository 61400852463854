#orderItemList{
    color: var(--main-grey);
}

section.xpartform {
    margin-top: 10px;
    display:block;
    width:33%;
    min-width: 560px;
    padding: 20px;
    background-color: #000;
    border-radius:25px;
}

.xpartKME select:read-only{
    background-color: #fff;
}

.xpartform > h1 {
    font-family: Roboto, Arial, sans-serif;
}

.list-container svg{
    color: var(--main-color);
    cursor: pointer;
}

.header-underline {
    height:3px;
    width: 200px;
    text-align: center;
    margin-bottom:30px;
    margin-top:-22px;
    border:none;
    background-image: linear-gradient(90deg, rgba(93,187,170,1) 20%, rgba(10,171,220,1) 39%, rgba(0,0,0,0) 40% ,rgba(0,0,0,0) 60%, rgba(10,171,220,1) 61% , rgba(60,114,184,1) 100%);
}

.header-underline-img {
    height: 44px;
    margin-top:-14px;
    text-align:center;
}

.xpm-warning-span{
    display: inline-block;
    color:var(--main-grey);
}

.xpart-list-grid-row-override{
    grid-template-columns: 
        60px 
        130px 
        105px
        minmax(100px, 1fr) 
        minmax(100px, 1fr) 
        72px 
        87px 
        60px 
        160px 
        175px 
        100px
        106px;
    min-width: 1300px;
}

.xpartform h1 {
    color:white;
    text-transform: uppercase;
}

.xpart-list-grid {
    overflow-x: auto;
}

.list-grid-technikum{
    grid-template-columns: 130px 1fr 1fr 80px 100px 140px 0.5fr 140px 140px;
}

.list-grid-xpart-general{
    grid-template-columns: 0.4fr 130px 0.7fr 90px 80px 120px 0.1fr 170px 140px 150px;
}

.multi-select{
    transition: all .3s;
}

.multiselect-selected{
    transform: scale(.99,.99);
    margin: 1px 0px;
    border: 2px solid green;
}

.optionalButtons .xpm-button:not(.confirmation-button){
    margin-left: 5px;
    width: 300px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
    .xpart-list-grid-row-override {
        min-width: unset;
    }
    section.xpartform {
        min-width:320px;
        border-radius: 0px;
    }
}
