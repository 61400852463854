.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--modal-window-z-index);
    overflow-y: auto;
}
  
.modal .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--modal-overlay-z-index);
  background: rgba(0,0,0,0.85);
}

.waiting {
  font-size: 30px;
  color: black;
}

.modal-content-background {
  padding: 4px;
  background: var(--background-gradient-to-right);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  z-index: var(--modal-window-z-index);
  overflow: visible;
  box-sizing: border-box;
}

.modal-dialog {
  width: 400px;
}

.modal-wide {
  width: 600px
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 300;
}

.modal .modal_content {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  white-space: pre-line;
  text-align: center;
  font-size: 16px;
}

@media only screen and (max-width: 786px) {
  .modal-content-background{
    width: 95% !important;
    min-width: 2px !important;
  }
}

.modal .modal_content > h2 {
  font-size: 28px;
  font-weight: 200;
  margin: 20px 0 40px;
  text-align: center;
}

.modal .modal_content .buttons_wrapper {
  padding: 20px;
}

.topnav {
  padding: 10px;
  text-align: center;
  background: var(--background-gradient-to-bottom);
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 40px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.button {
  margin: 50px auto 0;
  background: var(--background-gradient-to-bottom);
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  display: block;
  font-size: 20px;
  cursor: pointer;
}

.button_yes {
  margin-left: 0;
  margin: 0;
  float: inherit;
}

.button_no {
  margin-right: 0;
  margin: 0;
  float: right;
}

.center {
  text-align: center;
}
.modal_content_text{
margin-bottom: 20px;
}
