/* Styles for order list tables or Xpart list tables */

.list-grid{
    display: grid;
    grid: auto-flow / auto 1fr;
    grid-template-columns: 100%;
    border: 2px solid white;
    grid-template-rows: repeat(auto-fit, 45px);
    font-weight: bold;
    background-color: var(--main-grey);
}

.list-grid-row{
    border: 2px solid white;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: 40px 200px 110px 1fr 1fr 50px 50px 75px 80px 170px 110px;
}

.list-grid-row-headline{
    font-weight: 700;
    background: var(--background-gradient-to-right);
    color: black;
}

.list-grid-item{
    padding: 10px 3px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9em;
}

.toggle-disabled {
    background-color: gray !important;
}

.list-grid-checks svg{
    color: white !important;
    cursor: initial !important;
}

.list-grid-checks .xpm-yes{
    color: var(--main-color-pale) !important;
}


.list-grid-checks .xpm-no{
    color: #06D6A0;
}

.list-grid-item a{
    color: var(--main-color);
    text-align: left;
    text-decoration: none;
}

.list-grid-date-cell {
    font-style: italic;
}

.list-grid-special-cell {
    font-weight: 400;
    color: var(--secondary-color);
}

.list-grid-item-block span{
    display: block;
}

.list-grid-item-fake-link{
    color: var(--main-color);
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .list-grid {
        grid-row-gap: 20px;
        border: none;
        grid-template-rows: unset;
        padding-bottom: 20px;
    }

    .list-grid-row{
        grid-template-columns: 1fr!important;
    }

    .list-grid-row-headline{
        display: none;
    }

    .list-grid-item {
        display: grid;
        grid-template-columns: minmax(150px, 30%) 1fr;
        white-space: normal;
        border-bottom: solid 1px #54a874;
    }
   
    .list-grid-item::before{
        font-weight: 700;
        content: attr(data-name);
        color: var(--main-color);
        font-style: normal;
    }

    .list-grid-row .list-grid-item-link {
        padding: 10px 10px;
        border: none;
    }
}


.list-grid-no-mob {
    display: grid;
    grid: auto-flow / auto 1fr;
    grid-template-columns: 100%;
    border: 2px solid white;
    grid-template-rows: repeat(auto-fit, 45px);
    font-weight: bold;
    background-color: var(--main-grey);
}

.list-grid-row-no-mob {
    border: 2px solid white;
    grid-column-start: 1;
    grid-column-end: 1;
    background-color: #515151;
    display: grid;
    grid-template-columns: 40px 200px 110px 1fr 1fr 50px 50px 75px 80px 170px 110px;
}

.list-grid-row-headline-no-mob {
    font-weight: 700;
    background: var(--background-gradient-to-right);
    color: black;
}

.list-grid-item-no-mob {
    padding: 10px 3px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9em;
}
