body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Styles for datepicker and Bestellexport */

#date_from, #date_to {
  width:248px;   
  margin-left:32px;
}

span.header-underline-export {
  display:inline-block;
  height:20px;
  padding-top:10px;
}

.time_from_inaktiv, .time_to_inaktiv {
  background: rgba(200, 200, 200, 0.8);
  color:rgb(100,100,100);
  -webkit-filter:blur(1.2px);
          filter:blur(1.2px);
}

.infoLastDates {
  display: inline-block;
  width:148px;
}

#checkbox_time_from, #checkbox_time_to {
  transform:scale(1.5);
  height:15px;
  width:15px;
  margin-right:13px;
}

.form-export label{
  width:195px !important;
} 

@media only screen and (max-width: 700px) {
  .form-export input[type=text]{
    width:120px !important;
  } 
}

@media only screen and (max-width: 440px) {
  .form-export label {
    width:100px !important;
  }
  .form-export input[type=text]{
    width: 100px !important;
  }
}

/* "Helper-CSS (hide some Text for debug - we will remove later)"*/

/* hides text in file xparts.js, line 366 (<div id="orderItemList" data-help="debug log for orders and items. ignore."></div>) */
.xpm-go-to-photos-button + div#orderItemList {
  color:black;
}


@media only screen and (max-width: 700px) {
 .cameralayout{
    max-width: 80%;
    max-height: 80%;
  }
}

@media only screen and (min-width: 700px) {
  input:disabled {
    color:black;
    background-color: darkgray;
  }
  
  textarea:disabled {
    color:black;
    background-color: darkgray;
  }
  
  select:disabled {
    color:black;
    background-color: darkgray;
  }
}


/* END OF Helper-CSS */

.xpm-button-container {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(163px, 1fr));
}

.xpm-button-container > * {
    margin: 20px 0 0 0;
    height: 45px;
    padding: 0 15px;
    box-sizing: border-box;
}

.xpm-container-top{
    margin-top:-80px;
}

.button-alignment {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.button-alignment-right {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.xpm-button {
    height: 45px;
}

@media only screen and (max-width: 375px) {
    .xpm-button-container {
        grid-template-columns: 1fr;
    }

    /* .optionalButtons {
        margin-top: -40px;
    } */

    .button-alignment {
        flex-direction: column;
    }   
    
    .xpm-button {
        width: 100% !important;
        font-size: 12px !important;
        padding: 10px;
    }

    /* .list-container {
        margin-top: 40px;
    } */
}

/* Common styles for links, buttons, etc */

input, textarea, select{
    border-radius: 12px;
    border: none;
    min-height: 25px;
    box-sizing: border-box;
    padding: 0 10px;
}

/* Only Firefox */
@-moz-document url-prefix() {
    select {
        padding:5px 0 5px 5px;
    }
}

textarea{
    padding-top:2px;
}

input:focus, textarea:focus, select:focus {
    outline: none;    
}

.xpm-hidden{
    display: none !important;
}

.disabled-link {
    color: rgba(0,0,0,0);
    pointer-events: none;
    cursor: not-allowed;
}

.disabled-link svg{
    color: #555;
    color: rgba(0,0,0,0);
}

.xpm-button{
    text-align: center;
    background-color: var(--main-color);
    border-radius: 20px;
    border: none;
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    justify-content: center;
    transition: all 0.1s ease-in-out;
}

.xpm-button-right{
    right: 170px;
    position: absolute;
}

.xpm-button * {
    color: #fff;
    text-decoration: none;
}

.xpm-button:hover {
    background-color:var(--main-color-pale);
    color:var(--main-grey);
}

.xpm-disabled:hover {
    background-color:gray;    
    color:white;
}

.xpm-button :focus{
    outline: none;
}

.xpm-button-clear {
    background-color: black;
    border: 1px solid rgb(210, 9, 170);
}

.xpm-button-clear:hover {
    background-color: rgb(210, 9, 170);;
    color: var(--main-black);
}

.xpm-go-to-photos-button {
    width: 100%;
    height:45px;
    margin:20px 0;
    /*box-sizing: border-box;*/
}

.xpm-invalid {
    border-color: red;
}

.xpm-valid {
    border-color: green;
}

.xpm-warnings {
    font-size: 12px;
    text-align: right;
    height: 15px;
    overflow: hidden;
    transition: height .2s ease-in-out; 
    margin-top: 3px;
}

.xpm-warnings-inactive{
    height: 0px;
}

.save-button, .clear-button{
    margin-right: 0.5em;
}

.results--section {
    background-color: var(--main-grey-bright);
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
}

.soft-progress{
    cursor: progress;
}

.xpm-popup{
    position: absolute;
    display: block;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: 20px;
    width: 670px;
}

.xpm-popup-close{
    position: absolute;
    height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: var(--main-red);
}

.xpm-popup .historyRow span{
    min-width: 100px;
    margin-right: 10px;
    display: inline-block;
    width: 200px;
    padding: 5px;
}

.xpm-popup .historyRow span:nth-child(odd){
    background-color: var(--main-grey-bright)
}

.xpm-small-indicator{
    font-size: 8px;
    vertical-align: text-top;
}

.ProgressBar{
    padding: 14px;
}

.progress-total{
    width:  100%;
    background: var(--main-grey-bright);
    height:  20px;
    border-radius:  10px;
    overflow:  hidden;
}


.progress-loaded{
    background-color:  var(--main-color);
    width:  100%;
    height:  20px;
    border-radius:  5px;
    position:  relative;
    transition: transform .3s ease-in-out;
    transform: translateX(-100%);
}

.progressNumbers{
    text-align: right;
}

.file-upload{
    width: calc(100% - 28px);
    margin: 0px 14px;
}

.xpm-button-import[disabled]{
    background-color: var(--main-grey-bright);
    color: var(--main-grey)
}

.disabled-icon{
    color: #B8B8B8 !important;
    cursor: default !important;
}
.active-icon{
    color: rgb(93, 187, 161) !important;
    cursor: pointer !important;
}



@media only screen and (max-width: 786px) {
    .results--section{
        width: auto;
        padding: 0px;
        border-top: solid 1px var(--main-grey)  /* for collapsing margins fix */
    }

    .results--section>section {
        width: 100% !important;
    }
  
}

/* Styles for order info table */

.info-table{
    padding: 4px; 
    margin-bottom: 10px; 
    background: var(--background-gradient-to-right)
}

.info-table-inner-container{
    padding: 10px 15px 10px 5px;
    background-color: var(--main-grey)
}

.info-table-row{
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid white; 
} 

.info-table-row:first-child {
    border-top: 2px solid white; 
}

.info-table-cell {
    padding: 10px 15px;
    font-size: 100%;
}

.info-table-title {
    grid-column: 1 / span 4;
    background: var(--background-gradient-to-right);
    color: #fff; 
    padding: 10px 15px;
    font-size: 130%; 
    font-weight: bold;
    border-bottom: none;  
}

.info-table-attribute-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 700px) {
    .info-table-row{
        grid-template-columns: 1fr;
        border-bottom: none;
    }

    .info-table-attribute-container {
        border-bottom: 2px solid white; 
    }

    .info-table-attribute-container {
        display: grid;
        grid-template-columns: 1fr 1fr!important;
    }
    
}

.input-container{
    margin: auto;
    width: 100%;
    height: 100%;
    align-content: center;
    text-align: left;
    width: 500px;
}

.input-container>div{
    clear: both;
    margin-bottom: 15px;
}

.input-container div>label{
    width: 200px;
    display: block;
    margin-right: 20px;
    float: left;
    font-weight: bold;
    text-transform: uppercase;
}

.input-container input, .input-container select, .input-container textarea{
    width: 280px;
}

.xpm-input-with-checkbox{
    width: 248px !important;
}

.input-container input[type="checkbox"]{
    width: 25px;
    vertical-align: middle;
}

.input-container textarea{
    resize: none;
    height: 40px;
    vertical-align: top;
}

.input-container input, .input-container select, .input-container textarea{
    border: 1px solid gray;
}

.xpm-input .xpm-invalid {
    border: 2px solid red !important;
}

.xpm-scanner-input-container {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 10px;
    justify-items: stretch;
    margin-top: 20px
}

.xpm-scanner-input {
    margin: 0;
    width: auto !important;
}

.xpm-scanner-label {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 20px 30px;
    background: var(--background-gradient-to-bottom);
    display: grid;
    grid-column: 1 / span 2;
    text-transform: uppercase;
    font-weight: bold;
}

.xpm-scanner-button {
    padding: 0;
    height: 28px;
}

@media only screen and (max-width: 786px) {
    
    .input-container{
        margin-left: 10px;
        width: calc(100% - 10px);
    }
    .input-container input, .input-container select, .input-container textarea{
        width: calc(100% - 15px);
    }
    .input-container{
        margin: inherit;
    }
}

/* Styles for pagination of tables */

.paginationControls a, .paginationControls a:hover, .paginationControls a:visited, .paginationText{
    color: var(--main-grey);
    padding: 5px;
    text-decoration: none;
}

.paginationControls div, .paginationControls div:hover, .paginationControls div:visited {
display: inline-block;
color: var(--main-grey);
padding: 5px;
text-decoration: none;
cursor: pointer;
}


#paginationCurrent{
    font-size: 20px;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.paginationControls{
    margin: auto;
    width: 140px;
    margin-bottom: 30px;
    background-color: var(--main-grey-bright);
    color: var(--main-grey);
}


/* Styles for order list tables or Xpart list tables */

.list-grid{
    display: grid;
    grid: auto-flow / auto 1fr;
    grid-template-columns: 100%;
    border: 2px solid white;
    grid-template-rows: repeat(auto-fit, 45px);
    font-weight: bold;
    background-color: var(--main-grey);
}

.list-grid-row{
    border: 2px solid white;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: 40px 200px 110px 1fr 1fr 50px 50px 75px 80px 170px 110px;
}

.list-grid-row-headline{
    font-weight: 700;
    background: var(--background-gradient-to-right);
    color: black;
}

.list-grid-item{
    padding: 10px 3px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9em;
}

.toggle-disabled {
    background-color: gray !important;
}

.list-grid-checks svg{
    color: white !important;
    cursor: auto !important;
    cursor: initial !important;
}

.list-grid-checks .xpm-yes{
    color: var(--main-color-pale) !important;
}


.list-grid-checks .xpm-no{
    color: #06D6A0;
}

.list-grid-item a{
    color: var(--main-color);
    text-align: left;
    text-decoration: none;
}

.list-grid-date-cell {
    font-style: italic;
}

.list-grid-special-cell {
    font-weight: 400;
    color: var(--secondary-color);
}

.list-grid-item-block span{
    display: block;
}

.list-grid-item-fake-link{
    color: var(--main-color);
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .list-grid {
        grid-row-gap: 20px;
        border: none;
        grid-template-rows: unset;
        padding-bottom: 20px;
    }

    .list-grid-row{
        grid-template-columns: 1fr!important;
    }

    .list-grid-row-headline{
        display: none;
    }

    .list-grid-item {
        display: grid;
        grid-template-columns: minmax(150px, 30%) 1fr;
        white-space: normal;
        border-bottom: solid 1px #54a874;
    }
   
    .list-grid-item::before{
        font-weight: 700;
        content: attr(data-name);
        color: var(--main-color);
        font-style: normal;
    }

    .list-grid-row .list-grid-item-link {
        padding: 10px 10px;
        border: none;
    }
}


.list-grid-no-mob {
    display: grid;
    grid: auto-flow / auto 1fr;
    grid-template-columns: 100%;
    border: 2px solid white;
    grid-template-rows: repeat(auto-fit, 45px);
    font-weight: bold;
    background-color: var(--main-grey);
}

.list-grid-row-no-mob {
    border: 2px solid white;
    grid-column-start: 1;
    grid-column-end: 1;
    background-color: #515151;
    display: grid;
    grid-template-columns: 40px 200px 110px 1fr 1fr 50px 50px 75px 80px 170px 110px;
}

.list-grid-row-headline-no-mob {
    font-weight: 700;
    background: var(--background-gradient-to-right);
    color: black;
}

.list-grid-item-no-mob {
    padding: 10px 3px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9em;
}

:root {
    --main-color: #5dbba1;
    --main-color-pale: #C6EDE2;
    --secondary-color: #3c72b8;
    --main-grey: #515151;
    --main-grey-bright: #F1F1F2;
    --main-black: #1d1d1b;
    --main-red: #BA373E;
    
    --result-section-background-color: #333;
    
    --background-gradient-to-right: linear-gradient(to right, var(--main-color), var(--secondary-color));
    --background-gradient-to-bottom: linear-gradient(to bottom, var(--main-color), var(--secondary-color));

    --modal-overlay-z-index: 2999;
    --modal-window-z-index: 3000;
    --loader-spinner-overlay-z-index: 3001;
    --loader-spinner-z-index: 3002;
}

/* File for import global styles */
.topheader, .navbar{
    background-color: #000;
}
.currentUser{
    display: inline;
    position: absolute;
    top: 15px;
    right: 13px;
}
.topheader{
    position: fixed;
    width: 100%;
    z-index: 2000;
    height: 60px;
}
.topheader header {
    height: 100%;
}
.navbar{
    height: 100%;
}
.navbar-brand {
    height: 100%;
    width: 100%;
    position: relative;
}
.navbar-brand > a {
    position:absolute;
    left:50%;
    margin-left:-100px;
    line-height:54px;
}
img.navbar-item {
    position: relative;
    display:inline-block;
   /*margin-left: -100px;
    left: 50%;
   */
    width: 228px;
    min-width: 5%;
    vertical-align:middle;
}
h1, h2, h3 {
    color:black;
}
h1 {
    text-align: center;
    font-size: 30px;
}
#page-wrap{
    padding-top:60px;
}
/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    margin-right:5px;
}
/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}
/* Sidebar wrapper styles Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}
/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    height: 94% !important;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}
/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    box-sizing: border-box;
}
/* Individual item */
.bm-item {
    display: inline-block;
}
.bm-item, .bm-item:hover, .bm-item:visited{
    color: white;
    text-decoration: none;
    margin-bottom: 0.5em;
}
.bm-item span{
    margin-left: 10px;
}
/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    transition: none !important;
}
body{
    background-color: #F3F3F3;
    color: white;
}
span[title]{
    cursor: help;
}
.outer-container {
    display: grid;
    grid-template-rows: 1fr;
    min-height: 100vh;
}


.setting-style {
    display: grid;
    grid-template-columns: auto 1fr;
    text-decoration: none;
}

.email-properties,
.email-properties:active {
    grid-column: span 1;
    padding: 0 10px 0 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.user-icon-properties,
.user-icon-properties:active {
    grid-column: span 1;
    font-size: 30px;
    color: #ffffff;
}

@media only screen and (max-width: 768px) {
    img.navbar-item {
        position:relative;
        width: 25%;
        height:auto;
        min-width: 110px;
        margin-top:auto;
        margin-bottom:auto;
   }
   .email-properties {
       font-size: xx-small;
   }
}


.scan--section{
    color: black;
    font-weight: 700;
}

.scanner-container{
    width: 100%;
}

.input-container .scan-input {
    width: 100%;
    margin-bottom: 20px;
}

.scanner-container section{
    opacity: 1;
}

@-webkit-keyframes flash {
    0% {opacity: 1}
    50% {opacity: 0}
    100% {opacity: 1}
  }

@keyframes flash {
    0% {opacity: 1}
    50% {opacity: 0}
    100% {opacity: 1}
  }

.scanner-container-flash section{
    -webkit-animation-name: flash;
            animation-name: flash;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
}

.xpm-btn-list{
    list-style: none;
    margin-left: -60px;
    margin-top: 30px;
}

.xpm-btn-list li{
    padding: 22px 0px;
}

.xpm-btn-list a{
    background-color: #f39b2c;
    border: none;
    color: #fff;
    padding: 15px 32px;
    text-decoration: none;
    font-size: 16px;
    margin-top: 2em;
    margin-left: 2em;
}

.gallery li{
    float:left;
    cursor: pointer;
}

.gallery li img:hover{
    opacity: 0.5;
}
/* .print-button {
    background-color: #F39B2C;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    display: inline-block;
    margin-top: 2em;
    margin-left: 2em;
  }
.print-container {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align:center;
  align-content: center;
} */

/* ------------------------------------------------------------------------------------------------ */
/* ------ print */
.print-container {
  width: 500px;
  height: auto;
  margin: auto;
  padding: 20px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 20px;
  gap: 20px;
}

.print-container .print-input {
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.print-container .print-input-properties {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 0;
  border-radius: 25px;
  font-size: 16px;
}

.print-container .print-button {
  grid-column: span 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.print-container .print-button-properties {
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #F39B2C;
  border: none;
  font-size: 16px;
  color: white;
  text-align: center;
  text-decoration: none;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .print-container {
    width: 100%;
    padding: 20px;
    grid-template-columns: 1fr;
  }

  .print-container .print-button-properties {
    font-size: 16px !important;
  }
}

/*********** BEGIN CSS for SELECT NEW!!!!!! ***********/

.box select {
  background-color: #fff;
  border-radius: 12px;
  color: #fff;
  width: 280px;
  padding-left: 8px;
  /* height: 26px; */
  line-height: 14px;
  border: 1px solid gray;
  font-size: 14px;
  color: black;
  /* -webkit-appearance: button; */
  /* appearance: button; */
  outline: none;
  margin-top: -22px;
  z-index: 1000;
  margin-bottom: 15px;
}

/* .box::before {
  content: '\25B2';
  position: relative;
  display: inline-block;
  top: 6px;
  left: 250px;
  width: 25px;
  height: 27px;
  line-height: 18px;
  text-align: center;
  font-size: 20px;
  color: #000;
  pointer-events: none;
  transition: transform 0.1s ease-in-out;
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  border-radius: 12px;
  vertical-align: middle;
  z-index: 1;
  margin-bottom: 15px;
} */

.box:hover::before {
  border-radius: 12px;
  -moz-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  -o-transform: rotate(240deg);
  -webkit-transform: rotate(240deg);
}

.box select option {
  padding: 30px;
}

.box invalid {
  border: 3px solid red;
}

/*********** END CSS for SELECT NEW!!!!!! ************/

.formfields select:-moz-read-only, .formfields input:-moz-read-only, .formfields textarea:-moz-read-only {
  background-color:#d3d3d3;
}

.formfields select:read-only,
.formfields input:read-only,
.formfields textarea:read-only {
  background-color:#d3d3d3;
}

.formfields label {
  display:inline-block!important;
  width:180px!important;
  margin-right:0px!important;
}

.formfields label,
.formfields select,
.formfields input,
.formfields textarea {
  margin-top: 20px;
  /* color:#333; */
  margin-bottom: 15px;
}

.formfields textarea {
  height:60px;
  line-height:18px;  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size:13px;
  
}

.input-container {
  max-width: 580px;
}

/* .formfields select {
  margin-left:-24px;
} */


h1 {
  letter-spacing: 2px;
}

.form-container {
  padding-top: 20px;
}

.form-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
}

.form-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}


section.xpartform {
  margin-top: 10px;
  display:block;
  width:33%;
  min-width: 560px;
  padding: 20px;
  background-color: #000;
  border-radius:25px;
}

.xpartform > h1 {
  font-family: Roboto, Arial, sans-serif;
}


.xpartform h1 {
  color:white;
  text-transform: uppercase;
}


@media only screen and (max-width: 768px) {
  .xpart-list-grid-row-override {
        min-width: unset;
    }
  section.xpartform {
      min-width:320px;
      border-radius: 0px;
  }
  .input-container input, .input-container select, .input-container textarea {
    width: calc(100% - 15px);
    margin: 0px;
  }
}
#orderItemList{
    color: var(--main-grey);
}

section.xpartform {
    margin-top: 10px;
    display:block;
    width:33%;
    min-width: 560px;
    padding: 20px;
    background-color: #000;
    border-radius:25px;
}

.xpartKME select:-moz-read-only{
    background-color: #fff;
}

.xpartKME select:read-only{
    background-color: #fff;
}

.xpartform > h1 {
    font-family: Roboto, Arial, sans-serif;
}

.list-container svg{
    color: var(--main-color);
    cursor: pointer;
}

.header-underline {
    height:3px;
    width: 200px;
    text-align: center;
    margin-bottom:30px;
    margin-top:-22px;
    border:none;
    background-image: linear-gradient(90deg, rgba(93,187,170,1) 20%, rgba(10,171,220,1) 39%, rgba(0,0,0,0) 40% ,rgba(0,0,0,0) 60%, rgba(10,171,220,1) 61% , rgba(60,114,184,1) 100%);
}

.header-underline-img {
    height: 44px;
    margin-top:-14px;
    text-align:center;
}

.xpm-warning-span{
    display: inline-block;
    color:var(--main-grey);
}

.xpart-list-grid-row-override{
    grid-template-columns: 
        60px 
        130px 
        105px
        minmax(100px, 1fr) 
        minmax(100px, 1fr) 
        72px 
        87px 
        60px 
        160px 
        175px 
        100px
        106px;
    min-width: 1300px;
}

.xpartform h1 {
    color:white;
    text-transform: uppercase;
}

.xpart-list-grid {
    overflow-x: auto;
}

.list-grid-technikum{
    grid-template-columns: 130px 1fr 1fr 80px 100px 140px 0.5fr 140px 140px;
}

.list-grid-xpart-general{
    grid-template-columns: 0.4fr 130px 0.7fr 90px 80px 120px 0.1fr 170px 140px 150px;
}

.multi-select{
    transition: all .3s;
}

.multiselect-selected{
    transform: scale(.99,.99);
    margin: 1px 0px;
    border: 2px solid green;
}

.optionalButtons .xpm-button:not(.confirmation-button){
    margin-left: 5px;
    width: 300px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
    .xpart-list-grid-row-override {
        min-width: unset;
    }
    section.xpartform {
        min-width:320px;
        border-radius: 0px;
    }
}

.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-container img{
    border: none
}

.loader-lock-overlay {
    z-index: var(--loader-spinner-overlay-z-index);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
}

.loader-lock-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--loader-spinner-z-index);
}

.loader-max-height {
    height: 100%;
}

.loader-max-width {
    width: 100%;
}

.loader-big {
    width: 300px;
    height: 300px;
}

.loader-medium {
    width: 100px;
    height: 100px;
}

.loader-small {
    width: 50px;
    height: 50px;
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--modal-window-z-index);
    overflow-y: auto;
}
  
.modal .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--modal-overlay-z-index);
  background: rgba(0,0,0,0.85);
}

.waiting {
  font-size: 30px;
  color: black;
}

.modal-content-background {
  padding: 4px;
  background: var(--background-gradient-to-right);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  z-index: var(--modal-window-z-index);
  overflow: visible;
  box-sizing: border-box;
}

.modal-dialog {
  width: 400px;
}

.modal-wide {
  width: 600px
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 300;
}

.modal .modal_content {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  white-space: pre-line;
  text-align: center;
  font-size: 16px;
}

@media only screen and (max-width: 786px) {
  .modal-content-background{
    width: 95% !important;
    min-width: 2px !important;
  }
}

.modal .modal_content > h2 {
  font-size: 28px;
  font-weight: 200;
  margin: 20px 0 40px;
  text-align: center;
}

.modal .modal_content .buttons_wrapper {
  padding: 20px;
}

.topnav {
  padding: 10px;
  text-align: center;
  background: var(--background-gradient-to-bottom);
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 40px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.button {
  margin: 50px auto 0;
  background: var(--background-gradient-to-bottom);
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  display: block;
  font-size: 20px;
  cursor: pointer;
}

.button_yes {
  margin-left: 0;
  margin: 0;
  float: inherit;
}

.button_no {
  margin-right: 0;
  margin: 0;
  float: right;
}

.center {
  text-align: center;
}
.modal_content_text{
margin-bottom: 20px;
}

.xpm-disabled{
    background: gray;
}

.xpm-fake-btn{
    width: 200px;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ container: scanner */
.scan-container {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 20px;
}

.scan-container-cam {
  grid-column: span 2;
}

.scan-container-title {
  grid-column: span 2;
  padding: 20px 30px;
  background: var(--background-gradient-to-bottom);
}

.scan-container-form {
  grid-column: span 1;
}

.scan-container-form-alternativ {
  grid-column: span 2;
}

.scan-container-form-alternativ > input {
  margin: 0;
}

.scan-container-button {
  grid-column: span 1;
}

.scan-container-button-alternative {
  grid-column: span 2;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ style: scanner */
.scan-container-flash {
  opacity: 1;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scan-container-flash-action {
  -webkit-animation-name: flash;
          animation-name: flash;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.scan-title-properties {
  color: var(--main-grey);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.scan-form-properties {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  background-color: #ffffff;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 16px;
}

.scan-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  cursor: pointer;
}

.scan-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .scan-container {
    width: 100%;
    padding: 20px 20px 20px 20px;
  }
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ camera selector */
.cameraselector {
    color: rgba(33, 33, 33, 1);
    font-size: 1em;
    width: 300px;
}
.cameraselector__selectbox {
    display: flex;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 1em;
    box-shadow: inset 0 0 2px rgba(33, 33, 33, 0.86);
    z-index: 10;
}
.cameraselector__title {
    display: flex;
    align-items: center;
    flex-basis: 86%;
    flex-grow: 8;
    flex-shrink: 8;
    padding: 5px 10px;
}
.cameraselector__title--empty {
    display: flex;
    align-items: center;
    flex-basis: 86%;
    flex-grow: 8;
    flex-shrink: 8;
    padding: 5px 10px;
    color: rgba(33, 33, 33, 0.5);
}
.cameraselector__ctrl--open,.cameraselector__ctrl--close {
    display: flex;
    flex-basis: 12%;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-content: center;
    color: rgba(33, 33, 33, 0.86);
    font-size: 30px;
    background-color: rgba(33, 33, 33, 0.08);
    border-radius: 0 1em 1em 0;
}
.cameraselector__optionbox--show {
    display: flex;
    max-height: 120px;
    background-color: white;
    margin: 5px 0 0 0;
    overflow-y: hidden;
}
.cameraselector__optionbox--hide {
    display: none;
}
.cameraselector__optionlist {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 100%;
    padding-left: 0;
    margin: 0;
    box-shadow: 2px 0px 2px 2px rgba(33, 33, 33, 0.12);
    overflow-y: auto;
}
.cameraselector__optionitem {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.cameraselector__optionitem:hover {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    background-color: rgba(33, 33, 33, .86);
    box-shadow: inset 0  0 2px rgba(255, 255, 255, 0.22);
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
    .cameraselector {
        width: auto;
    }
}


#xparts-info {
    padding:5px;
    text-align:center;
}    
.switchtoggle-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  display: none;
}

.react-switch-label {
  width: 26px;
  height: 14px;
  background: #f04770;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.2s;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

/* .react-switch-label:active .react-switch-button {
  width: 26px;
} */
.order-list-grid-row-override{
    grid-template-columns: 
        minmax(125px, 200px) 
        minmax(120px, 200px) 
        minmax(105px, 250px) 
        minmax(105px, 250px) 
        minmax(110px, 150px) 
        minmax(200px, 350px) 
        minmax(120px, 200px) 
        minmax(200px, 220px) 
    }

#orderItemList{
    color: var(--main-grey);
}

.delivery-group-overview-list{
    grid-template-columns: 
    minmax(120px, 0.75fr) 
    minmax(150px, 0.5fr) 
    minmax(100px, 0.75fr) 
    minmax(100px, 00.75fr) 
    minmax(110px, 0.75fr)
    minmax(90px, 0.75fr)
    minmax(90px, 0.5fr) 
}

.list-xpart {
    grid-template-columns: 
        minmax(120px, 0.75fr) 
        minmax(150px, 0.5fr) 
        minmax(100px, 100px) 
        minmax(100px, 1.5fr)
        minmax(100px, 0.75fr) 
        minmax(100px, 00.75fr) 
        minmax(110px, 0.75fr)
        minmax(90px, 0.75fr)
        minmax(90px, 0.5fr) 
 
}

.list-xpart-evs-receive {
    grid-template-columns: 
        minmax(100px, 0.3fr)
        minmax(100px, 0.3fr)
        minmax(100px, 0.3fr)
}

.list-xpart-kme-assebly {
    grid-template-columns: 
        minmax(120px, 0.3fr) 
        minmax(120px, 0.3fr) 
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr) 
        minmax(120px, 0.3fr)
        minmax(20px, 0.1fr)
}

.list-xpart-kme-requested {
    grid-template-columns: 
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(20px, 0.1fr)
}

.list-grid-xpart-general-evs {
    grid-template-columns: 
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.1fr)
        minmax(120px, 0.1fr)
        minmax(20px, 0.1fr)
        minmax(20px, 0.1fr)
}

.list-xpart-assebly-commissions {
    grid-template-columns: 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
}


.list-xpart-supplier-kommissionieren {
    grid-template-columns: 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
}


.list-package-supplier-picking {
    grid-template-columns: 
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr)
        minmax(110px, 0.2fr)
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr)     
}


.list-xpart-qa {
    grid-template-columns: 
        90px
        minmax(100px, 146px)
        minmax(100px, 160px)
        minmax(100px, 120px)
        minmax(100px, 150px)
        minmax(100px, 260px)
        minmax(100px, 1fr)
        90px
        minmax(100px, 1fr)
        90px
}

.list-group-xpart {
    grid-template-columns: 
    minmax(100px, 100px) 
    minmax(100px, 1fr) 
    minmax(100px, 100px)
    minmax(100px, 100px)
    minmax(100px, 130px)
}

.xpart-into-delivery-group {
    grid-template-columns: 
    minmax(80px, 1fr) 
    minmax(80px, 1fr) 
    minmax(150px, 1fr)
    minmax(80px, 1fr)
    minmax(80px, 1fr)
    minmax(80px, 1fr)
}


.list-group-xpart-all {
    grid-template-columns: 
    minmax(100px, 1fr) 
    minmax(100px, 1fr) 
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
}

.list-group-xpart-check-table {
    grid-template-columns: 
    minmax(100px, 1fr) 
    minmax(100px, 1fr) 
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
}

.list-grid-item {
    text-overflow: ellipsis !important;
    overflow:hidden !important;
}


.excel-export {
    position:absolute;
    width:280px;
    height:40px;
    top:70px;
    right:30px;
    color:black;
}

.mapfunctioncolumn {
    display: grid;
    grid-template-columns: repeat(4, -webkit-max-content);
    grid-template-columns: repeat(4, max-content);
    grid-gap: 12px;
}

.mapfunctioncolumn .column-1 {
    grid-column: span 1;
}

.not-active-element {
    color: #B8B8B8 !important;
    cursor: default !important;
}
.order-item-list-grid-row-override{
    grid-template-columns: 
        minmax(150px, 160px) 
        minmax(90px, 140px) 
        minmax(150px, 200px)
        minmax(100px, 1fr) 
        minmax(70px, 80px) 
        minmax(90px, 120px) 
        minmax(100px, 110px) 
        minmax(130px, 140px)
        minmax(110px, 120px)
}

.order-item-list-container{
    display: block;
    padding: 30px 0;
}

.order-item-list-container .xpm-button{ 
    margin: 30px 14px;
    height: 45px;
    padding: 0 15px;
    box-sizing: border-box;
}

.order-item-list-container svg{
    color: var(--main-color);
    cursor: pointer;
}

#orderItemList{
    color: var(--main-grey);
}

.function-column-space{
    margin-right: 10px;
}
.order-item-completion-status{
    display: flex !important;
    font-weight: bold;
    color:black;
}
.order-item-overview-list-grid-row-override{
    grid-template-columns:
        135px 
        minmax(100px, 1fr) 
        minmax(114px, 1fr) 
        72px 
        125px 
        150px 
        minmax(100px, 120px)
}

.add-xpart-button {
    width: 250px;
    margin: 10px 0;
}

.description-cell {
    grid-column: 1 / span 2;
    grid-template-columns: 1fr 3fr;
}

.xpm-link-btn a, .xpm-link-btn>span{
    width: 200px;
    height: 30px;
    display: block;
    color: white;
    padding: 10px;
    background-color: #f39b2c;
    text-align: center;
    cursor: pointer;
}

.xpm-link-btn a{
    color: white;
    text-decoration: none;
}

.xpm-link-btn a:visited{
    color: white;
}

.xpm-right{
    position: absolute;
    right: 20px;
    bottom: 20px;
}

#ServerGallery img{
    border: 2px solid var(--main-color);
}

#ServerGallery>div{
    float: left;
}

#ServerGallery>div>span{
    font-weight: 700;
    padding-left: 20px;
    display: block;
    margin-bottom: 25px;
}

.photo-gallery-img-delete {
    position: absolute;
    right: 0px;
    top:0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    color:#000;
    background-color: #FFF;
    padding: 0 9px;
    border-radius: 25px;
}

.photo-gallery-img-delete:hover {
    cursor: pointer;
}

.gallery > div {
    position:relative;
    display:inline-block;
}

.xpm-clear{
    clear: both;
}

.xpm-disabled span{
    background: grey;
}

.photo-gallery-button-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: space-between;
}

.gallery img{
    width: 550px;
}

@media only screen and (max-width: 768px) {
    .gallery img{
        width: 275px;
    }
    .xpm-camera-mode#page-wrap{
        padding-top: 0px !important;
    }
    .xpm-camera-mode.topheader{
        display: none !important;
    }
}
@media only screen and (max-width: 425px) {
    .gallery img{
        width: 100%;
    }
}
.confirmation-button{
    align-items: center;
    position: fixed;
    right: 0px;
    padding: 20px;
    transition: width 1s;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    white-space: nowrap;
    z-index: 10;
    border: solid 1px white;
    border-right: none;
    transition: .1s all ease-in-out;
}

@media only screen and (max-width: 768px) {
    .confirmation-button{
        width: 100px;
        bottom: 50px;
        top: unset;
        margin-right: 0px;
    }
}

.button-add-item{
    display: none;
}
#filterInput{
    width: 95%;
    min-width: 200px;
    max-width: 500px;
    left:20px;
}
.filter-list-item{
    cursor: default;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    color: black;
    background-color: var(--main-color-pale);
    border-radius: 16px;
    padding: 5px 10px;
    margin-right: 4px;
}

.filter-list-item li{
    list-style: none;
}

.search-bubble-delete{
    -webkit-appearance: none;
   -moz-appearance: none;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    max-height: 20px;
    max-width: 20px;
    outline: 0;
    position: relative;
    vertical-align: top;
    width: 10px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-left: 10px;
}

.filter-list{
    font-weight: normal;
    font-size: 12px !important;
    width: 95% !important;
    margin-left:20px !important;
    clear: left;
    cursor: text;
    list-style-type: none;
    min-height: 1px;
    overflow: hidden;
    outline: medium none;
    color: #1d1d1b !important;
    padding: 10px 0px !important;
    border: solid 0px #dedede;
    transition: all 0.5s ease;
}

.icon-remove{
color: var(--main-color);
cursor: pointer;
margin-top: 5px;
margin-right: 5px;
}

.search-container{
    margin-bottom: 10px;
    width: 25%;
    min-width: 220px;
    max-width: 500px;
    background-color: white;
    border-radius: 20px;
}

.filter-form{
    display: flex;
}

label, select, input{
    margin-top: 0px !important;
}

.specialFilter{
    position: absolute;
    width: 50%;
    min-width: 280px;
    max-width: 400px;
    display: block;
    left:20px;
    margin-top: 6px;
    z-index: 1900;
    color: gray;
    border: 6px solid rgba(255,255,255,0.7);
    background: rgba(255,255,255,0.7);
    transition: background 0.2s ease-in-out 0s;
    border-radius: 5px;
}

.specialFilter:hover{
    background: rgba(255,255,255,1);
}

#specialFilterBorder{
    border: 1px solid gray; 
    border-radius: 5px;  
}

.specialFilter ul{
    list-style: none;
}

.specialFilter li{
    margin: 0 0 10px -30px;
}

.specialFilter input, .specialFilter select{
    border-bottom: 1px solid gray;
    border-radius: 0px;
    width: 50%;
    margin-top: -6px;
    display: inline-block;
    position: absolute;
    background: rgba(255,255,255,0);
}

.specialFilter label{
    width: 40%;
    display: inline-block;
    font-size: 13.3333px;
}

@media only screen and (max-width: 783px) {
    .filter-list{
        margin-left:20px!important;
    }  
    .specialFilter {
        left:0px;
    } 
}
/* ------------------------------------------------------------------------------------------------ */
/* ------ style: createuser */
.loginuser-container {
  padding: 20px;
  box-sizing: border-box;
}

.loginuser-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-left-alignment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-right {
  grid-column: span 1;
  height: 100%;
}

.form-forgetpassword-alignment {
  height: 100%;
  padding: 0 30px;
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
}

.form-full {
  grid-column: span 2;
  height: 100%;
}

.loginuser-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.form-title-left {
  font-weight: bold;
  color: var(--main-grey);
  text-transform: uppercase;
}

.form-input-properties {
  width: 100%;
  height: 25px;
  margin: 0;
  border: 1px solid gray;
}

.form-select-properties {
  width: 100%;
  height: 30px;
  margin: 0;
  border: 1px solid gray;
}

.input-selection-container {
  max-height: 200px;
  overflow: auto;
}

.input-selection-properties {
  margin: 0;
  padding: 0 0 0 10px;
  list-style-type: none;
}

.input-selection-properties li {
  padding: 5px 0 5px 0;
  cursor: pointer;
}

.loginuser-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.loginuser-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

.form-warning {
  border-color: #ff0000;
}

/* select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
} */

.selectbox-properties {
	position: relative;	
}

/* .selectbox-properties::before {
  width: 17px;
  height: 14px;
  padding-top: 3px;
  content: '\25B2';
  font-size: 20px;
  line-height: 8px;
  color: #000000;
  position: absolute;
  top: 6px;
  right: 12px;
  pointer-events: none;
  transform-origin: 50% 55%;
  transform: rotate(180deg);
} */

select::-ms-expand {
  display: none;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .loginuser-form-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-left-alignment {
    align-items: center;
  }

  .form-full {
    grid-column: span 1;
  }
}

.error-page-content {
    width: 100%;
    height: 100%;
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10vw;
    font-weight: bold;
}
.xpartpackage {
    grid-template-columns: 
        minmax(120px, 0.20fr) 
        minmax(120px, 0.20fr) 
        minmax(200px, 0.55fr) 
       10px;
}

.deliverylist {
    grid-template-columns: 
    minmax(200px, 0.20fr) 
    minmax(200px, 0.20fr) 
    minmax(200px, 0.55fr) 
}

.packagelist {
    grid-template-columns: 
        minmax(100px, 0.20fr) 
        minmax(100px, 0.20fr) 
        minmax(135px, 0.10fr) 
        minmax(135px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(150px, 0.10fr) 
        minmax(150px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(100px, 0.10fr) 
}

.infotext {
    color:#000;
    text-align:center;
    margin:-30px 0 20px 0;
}

.list-grid-row {
    background-color: #515151;
}
#orderItemList{
    color: var(--main-grey);
}

#inputmain {
    width:100%;
}

.button-info {
    display:block;
    height:60px !important;
    width:350px;
    position:fixed;
    top:150px;
    right:-15px;
    margin-top: 15px;
    border-top-right-radius:0px;
    border-bottom-right-radius:0px; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #387060 0%, #387060 40%,#5dbba1 41%, #5dbba1 100%);
    z-index: 1999;
    transition: 0.2s;
}

.button-info:hover { /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #5ca38f 0%, #5ca38f 40%,#c6ede2 41%, #c6ede2 100%);  
}

.button-info:hover > .button-info-line-bottom, .button-info-line-top {
    color:#515151 !important;     
}

.button-info-line-top, .button-info-line-bottom {
    display:block !important;
    color:white !important;
    transition: 0.2s;
}

.button-info-line-top {
    margin-top:-6px;
    font-size:14px;
}

.button-info-line-bottom {
    margin-top:10px;
}

.button-delivery {
    right:0px;
}

section.xpartform {
    margin-top: 10px;
    display:block;
    width:33%;
    max-width: 520px;
    padding: 20px;
    background-color: #000;
    border-radius:25px;
}

.xpartform > h1 {
    font-family: Roboto, Arial, sans-serif;
}

.list-container svg{
    color: var(--main-color);
    cursor: pointer;
}

.header-underline {
    height:3px;
    width: 200px;
    text-align: center;
    margin-bottom:30px;
    margin-top:-22px;
    border:none;
    background-image: linear-gradient(90deg, rgba(93,187,170,1) 20%, rgba(10,171,220,1) 39%, rgba(0,0,0,0) 40% ,rgba(0,0,0,0) 60%, rgba(10,171,220,1) 61% , rgba(60,114,184,1) 100%);
}

.header-underline-img {
    height: 44px;
    margin-top:-14px;
    text-align:center;
}

.xpm-warning-span{
    display: inline-block;
    color:var(--main-grey);
}

.info-display{
    display: block;
    color:var(--main-grey);
    font-weight: 700;
}

.info-display-list{
    margin-bottom: 20px;
    margin-top: 10px;
}

.xpartform h1 {
    color:white;
    text-transform: uppercase;
}

.xpart-list-grid {
    overflow-x: auto;
}

.multi-select{
    transition: all .3s;
}

.multiselect-selected{
    transform: scale(.99,.99);
    margin: 1px 0px;
    /* border: 2px solid green; */
    border: none;
    background-color:#888;
}

.optionalButtons .xpm-button:not(.confirmation-button){
    margin-left: 0px;
    width: 300px;
    margin-bottom: 10px;
}

.input-container {
    max-width:580px;
}

.infield, .infield .xpm-warnings {
    display:inline-block;
    width:33%;
    height:30px;
    text-align:left !important;
}

.infield .xpm-warnings {
    display:inline-block;
    width:90%;
    text-align:left !important;
    color:red;
}

.xpm-warnings{
    color: var(--main-grey);
    text-align:left;
}
#packagetype {
    height:20px;
 }

.infield .xpm-warnings-inactive{
  visibility: hidden;
}

.infield input, .infield select {
    width:90%;
    padding: 0 0 0 5px;
}

input {
    height:20px;
}

.infield label {
    margin:10px 0;
}

.field1 {
    width:100%;
}

label {
    color: var(--main-grey);
    font-weight:bold;
}

.field {
    display:inline-block;
    width:33%;
    margin-bottom: 20px;
}

.editfield1 {
    display:block;
}

.field label {
    display:inline-block;
    width:180px;
    font-size:14px !important;
}

.info-display {
    margin-bottom:20px;
    display:inline-block;
}

.info-header {
    font-weight:bold;
    text-align: center;
    margin-top:15px;
}

.packageedit {
    position:absolute;
    padding:10px 30px;
    display:inline-block;
    right:10px;
}

.modal .modal_content {
    padding:10px;
    font-size:14px;
}

.icon-properties {
    margin-right: 12px;
}

.delete-item-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.delete-item-content {
    grid-column: span 2;
    font-size: 120%;
    line-height: 170%;
    text-align: left;
}

.delete-item-button {
    grid-column: span 1;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-end;
    text-align: center;
}

.delete-item-button > .xpm-button {
    margin: auto;
    width: 220px;
}

.xpm-input {
    line-height:25px;
    min-height:25px;
}

.xpm-invalid {
    border: 2px solid red !important;
}

#packagetype.invalid {
    border: 2px solid red !important;
}

@media only screen and (max-width: 768px) {
    .xpart-list-grid-row-override {
        min-width: unset;

    }
    .section.xpartform {
        min-width: unset;
        display: grid;
    }

   
    .results--section{
        min-width: unset;
    }
}

@media only screen and (max-width: 600px) {
    .infield, .infield .xpm-warnings {
        width:100%;
    }

    .optionalButtons .xpm-button:not(.confirmation-button){
        margin-left: 5px;
        width: 190px;
        margin-bottom: 10px;
    }

    .infield .xpm-warnings {
        height:15px;
    }
}    


@media only screen and (max-width: 375px) {
    .xpart-list-grid-row-override {
        min-width: unset;
    }
    .optionalButtons .xpm-button:not(.confirmation-button){
        margin-left: 5px;
        width: 190px;
        margin-bottom: 10px;
    }

    
}

.content-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.header-button-alignment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.header-button {
  margin-left: 20px;
  padding: 10px 30px;
}

.edit-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.edit-field {
  padding: 10px 0 10px 0;
  font-size: 14px;
}

.edit-field label {
  width: 200px;
  display: inline-block;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column-1 {grid-column: span 1;}
.column-2 {grid-column: span 2;}
.column-3 {grid-column: span 3;}
.column-4 {grid-column: span 4;}
.column-5 {grid-column: span 5;}
.column-6 {grid-column: span 6;}

.button-container > .xpm-button {
  width: 300px;
  margin: 10px 0 10px 0;
}

.button-container > .xpm-hidden {
  display: none !important;
}

/* ------------------------------------------------- */
@media only screen and (max-width: 768px) {

}

/* ------------------------------------------------- */
@media only screen and (max-width: 375px) {
  .content-container {
    grid-template-columns: 1fr;
  }

  .edit-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .header-button-alignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .header-button {
    margin: 10px 0 10px 0;
    padding: 10px 30px;
  }

  .edit-field label {
    width: 150px;
    display: inline-block;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .column-xs-1 {grid-column: span 1;}
  .column-xs-2 {grid-column: span 2;}
  .column-xs-3 {grid-column: span 3;}
  .column-xs-4 {grid-column: span 4;}
  .column-xs-5 {grid-column: span 5;}
  .column-xs-6 {grid-column: span 6;}

  .button-container > .xpm-button {
    width: auto;
    margin: 10px 0 10px 0;
  }
}
.list-none ul{
    list-style-type: none;
    padding: 0;
}
/* Menu Styles */
/* Position and sizing of burger button */
.bm-burger-button {
    left:10px !important;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 20px;
    top: 15px;
  }

.bm-burger-bars-hover { 
    opacity:1 !important;  
}    

.bm-burger-bars {
    background-image: linear-gradient(90deg, rgba(93,187,170,1) 33%, rgba(10,171,220,1) 67%, rgba(60,114,184,1) 100%) !important;
    transition: transform 0.5s ease-in-out!important;
    height: 4px !important;
}

.bm-burger-button-click > span > .bm-burger-bars:first-child{
    transform: translateY(10px) rotate(45deg) !important;
    opacity:1 !important;
 }

.bm-burger-button-click > span > .bm-burger-bars:nth-child(3) {
    transform: translateY(-14px) rotate(-45deg) !important;
    opacity:1 !important;
}
  
.bm-burger-button-click > span > .bm-burger-bars:nth-child(2) {
    transform: translateX(-100px) !important;
 }  

.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 65px;
  width: 300px;
}

.notifications--hide {
    display: none;
}

.notifications__message--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: rgba(93, 187, 161, 0.96);
  padding: 0.5em;
  border-color: rgba(93, 187, 161, 0.96);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 5px;
}

.notifications__message--warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: rgba(255, 221, 87, 0.96);
  padding: 0.5em;
  border-color: rgba(255, 221, 87, 0.96);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 5px;
}

.notifications__message--error {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0.5em;
  background-color: rgba(241, 70, 104, 0.96);
  border-color: rgba(241, 70, 104, 0.96);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 5px;
}

.notifications__title {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  font-size: 1.414em;
  padding-top: 0.5em;
}

.notifications__content {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  font-size: 1em;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ style: setting */
.setting-container {
  padding: 20px;
  box-sizing: border-box;
}
.setting-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-left-alignment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-full {
  grid-column: span 2;
  height: 100%;
}

.setting-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.form-title-left {
  font-weight: bold;
  color: var(--main-grey);
  text-transform: uppercase;
}

.form-input-properties {
  width: 100%;
  height: 25px;
  margin: 0;
  border: 1px solid gray;
}

.setting-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.setting-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

.form-warning {
  border-color: #ff0000;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .setting-form-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-left-alignment {
    align-items: center;
  }

  .form-full {
    grid-column: span 1;
  }
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ style: createuser */
.createuser-container {
  padding: 20px;
  box-sizing: border-box;
}

.createuser-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-left-alignment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-right {
  grid-column: span 1;
  height: 100%;
}

.form-full {
  grid-column: span 2;
  height: 100%;
}

.createuser-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.form-title-left {
  font-weight: bold;
  color: var(--main-grey);
  text-transform: uppercase;
}

.form-input-properties {
  width: 100%;
  height: 25px;
  margin: 0;
  border: 1px solid gray;
}

.form-select-properties {
  width: 100%;
  height: 30px;
  margin: 0;
  border: 1px solid gray;
}

.input-selection-container {
  max-height: 200px;
  overflow: auto;
}

.input-selection-properties {
  margin: 0;
  padding: 0 0 0 10px;
  list-style-type: none;
}

.input-selection-properties li {
  padding: 5px 0 5px 0;
  cursor: pointer;
}

.createuser-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.createuser-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

.form-warning {
  border-color: #ff0000;
}

/* select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
} */

.selectbox-properties {
	position: relative;	
}
/* 
.selectbox-properties::before {
  width: 17px;
  height: 14px;
  padding-top: 3px;
  content: '\25B2';
  font-size: 20px;
  line-height: 8px;
  color: #000000;
  position: absolute;
  top: 6px;
  right: 12px;
  pointer-events: none;
  transform-origin: 50% 55%;  
  transform: rotate(180deg);
} */

select::-ms-expand {
  display: none;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .createuser-form-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-left-alignment {
    align-items: center;
  }

  .form-full {
    grid-column: span 1;
  }
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ style: paswordforgotten */
.resetpassword-container {
  padding: 20px;
  box-sizing: border-box;
}

.resetpassword-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.resetpassword-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.linktologinpage {
  color: var(--main-color);
  cursor: pointer;
}

.resetpassword-button {
  width: 100%;
}

.firemodal-overlay-container {
  width: 100%;
  height: auto;
}

.firemodal-overlay-container .content-container {
  padding: 0 20px 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firemodal-overlay-container .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firemodal-overlay-container .button-properties {
  width: 280px;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.firemodal-overlay-container .button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}
/* ------------------------------------------------------------------------------------------------ */
/* ------ style: confirmpassword */
.confirmpassword-container {
  padding: 20px;
  box-sizing: border-box;
}

.confirmpassword-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}
/* -------------------------------------------------------- */
/* group */
.group-content-grid-container {
  widows: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr 150px 1fr;
  grid-gap: 20px;
}

.group-content-grid-container .column-1 {
  grid-column: span 1;
}

.group-content-grid-container .info-display {
  margin: 0;
}

.group-content-grid-container #select-properties {
  width: 200px;
}

/* -------------------------------------------------------- */
/* mobile view */

@media only screen and (max-width: 375px) {
  /* ------------------------------------------------------ */
  /* group */
  .group-content-grid-container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .value-field {
    padding: 5px 0 20px 0;
  }
}

.info-display{
    display: block;
    color:var(--main-grey);
    font-weight: 700;
}

 .wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
  }
 
  .headerClass{
    width: "90%";
  }
  .extraButtons{
    width:300px; 
  }
  
  @media only screen and (max-width: 375px) {
    .wrapper {
      content: '';
      float: left;
      height: 150px;
      margin: 0 0 15px 15px;
      display: flex;
      flex-direction: column-reverse;
    }
    .buttons {
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
    }
    .headerClass{
      width: "90%";
    }
    .extraButtons{
      width:300px; 
    }
   
  }
  
  @media screen and (max-width:600px) {
    .wrapper {
      content: '';
      float: left;
      height: 150px;
      margin: 0 0 15px 15px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
    .buttons {
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
    }
    .headerClass{
      width: "90%";
    }
    .extraButtons{
      width:300px; 
    }
  }
/* mobile view */
.header-column-wrapper {
  width: 100%;
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
}

.header-column-left {
  grid-column: span 1;
  display: none;
  order: 0;
}

.header-column-middle {
  grid-column: span 1;
  order: 1;
}

.header-column-right {
  grid-column: span 1;
  order: 3;
}

.header-column-pagination {
  grid-column: span 1;
  box-sizing: border-box;
  display: grid;
  place-content: center center;
  order: 2;
}

.extra-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.extra-Button {
  max-width: 100%;
  margin: 0 0 10px 0;
}

/* table view */
@media (min-width: 376px) {
  .extra-button-wrapper {
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .extra-Button {
    width: 190px;
  }
}

/* table view */
@media (min-width: 600px) {
  .extra-Button {
    width: 300px;
  }
}

/* desktop view */
@media (min-width: 701px) {
  .header-column-left {
    display: block;
  }

  .header-column-wrapper {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .header-column-right {
    order: 2;
  }

  .header-column-pagination {
    grid-column: span 3;
    padding: 5px;
    box-sizing: border-box;
    display: grid;
    place-content: center center;
    order: 3;
  }

  .extra-button-wrapper {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}
.InfoText{
    color: black;
}
