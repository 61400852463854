/* ------------------------------------------------------------------------------------------------ */
/* ------ style: confirmpassword */
.confirmpassword-container {
  padding: 20px;
  box-sizing: border-box;
}

.confirmpassword-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}