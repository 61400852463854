/* Menu Styles */
/* Position and sizing of burger button */
.bm-burger-button {
    left:10px !important;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 20px;
    top: 15px;
  }

.bm-burger-bars-hover { 
    opacity:1 !important;  
}    

.bm-burger-bars {
    background-image: linear-gradient(90deg, rgba(93,187,170,1) 33%, rgba(10,171,220,1) 67%, rgba(60,114,184,1) 100%) !important;
    transition: transform 0.5s ease-in-out!important;
    height: 4px !important;
}

.bm-burger-button-click > span > .bm-burger-bars:first-child{
    transform: translateY(10px) rotate(45deg) !important;
    opacity:1 !important;
 }

.bm-burger-button-click > span > .bm-burger-bars:nth-child(3) {
    transform: translateY(-14px) rotate(-45deg) !important;
    opacity:1 !important;
}
  
.bm-burger-button-click > span > .bm-burger-bars:nth-child(2) {
    transform: translateX(-100px) !important;
 }  
