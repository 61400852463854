/* mobile view */
.header-column-wrapper {
  width: 100%;
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
}

.header-column-left {
  grid-column: span 1;
  display: none;
  order: 0;
}

.header-column-middle {
  grid-column: span 1;
  order: 1;
}

.header-column-right {
  grid-column: span 1;
  order: 3;
}

.header-column-pagination {
  grid-column: span 1;
  box-sizing: border-box;
  display: grid;
  place-content: center center;
  order: 2;
}

.extra-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.extra-Button {
  max-width: 100%;
  margin: 0 0 10px 0;
}

/* table view */
@media (min-width: 376px) {
  .extra-button-wrapper {
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .extra-Button {
    width: 190px;
  }
}

/* table view */
@media (min-width: 600px) {
  .extra-Button {
    width: 300px;
  }
}

/* desktop view */
@media (min-width: 701px) {
  .header-column-left {
    display: block;
  }

  .header-column-wrapper {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .header-column-right {
    order: 2;
  }

  .header-column-pagination {
    grid-column: span 3;
    padding: 5px;
    box-sizing: border-box;
    display: grid;
    place-content: center center;
    order: 3;
  }

  .extra-button-wrapper {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}