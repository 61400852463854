.switchtoggle-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  display: none;
}

.react-switch-label {
  width: 26px;
  height: 14px;
  background: #f04770;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.2s;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

/* .react-switch-label:active .react-switch-button {
  width: 26px;
} */