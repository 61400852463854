/* Styles for order info table */

.info-table{
    padding: 4px; 
    margin-bottom: 10px; 
    background: var(--background-gradient-to-right)
}

.info-table-inner-container{
    padding: 10px 15px 10px 5px;
    background-color: var(--main-grey)
}

.info-table-row{
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid white; 
} 

.info-table-row:first-child {
    border-top: 2px solid white; 
}

.info-table-cell {
    padding: 10px 15px;
    font-size: 100%;
}

.info-table-title {
    grid-column: 1 / span 4;
    background: var(--background-gradient-to-right);
    color: #fff; 
    padding: 10px 15px;
    font-size: 130%; 
    font-weight: bold;
    border-bottom: none;  
}

.info-table-attribute-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 700px) {
    .info-table-row{
        grid-template-columns: 1fr;
        border-bottom: none;
    }

    .info-table-attribute-container {
        border-bottom: 2px solid white; 
    }

    .info-table-attribute-container {
        display: grid;
        grid-template-columns: 1fr 1fr!important;
    }
    
}
