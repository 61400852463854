/* -------------------------------------------------------- */
/* group */
.group-content-grid-container {
  widows: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr 150px 1fr;
  grid-gap: 20px;
}

.group-content-grid-container .column-1 {
  grid-column: span 1;
}

.group-content-grid-container .info-display {
  margin: 0;
}

.group-content-grid-container #select-properties {
  width: 200px;
}

/* -------------------------------------------------------- */
/* mobile view */

@media only screen and (max-width: 375px) {
  /* ------------------------------------------------------ */
  /* group */
  .group-content-grid-container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .value-field {
    padding: 5px 0 20px 0;
  }
}