.input-container{
    margin: auto;
    width: 100%;
    height: 100%;
    align-content: center;
    text-align: left;
    width: 500px;
}

.input-container>div{
    clear: both;
    margin-bottom: 15px;
}

.input-container div>label{
    width: 200px;
    display: block;
    margin-right: 20px;
    float: left;
    font-weight: bold;
    text-transform: uppercase;
}

.input-container input, .input-container select, .input-container textarea{
    width: 280px;
}

.xpm-input-with-checkbox{
    width: 248px !important;
}

.input-container input[type="checkbox"]{
    width: 25px;
    vertical-align: middle;
}

.input-container textarea{
    resize: none;
    height: 40px;
    vertical-align: top;
}

.input-container input, .input-container select, .input-container textarea{
    border: 1px solid gray;
}

.xpm-input .xpm-invalid {
    border: 2px solid red !important;
}

.xpm-scanner-input-container {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 10px;
    justify-items: stretch;
    margin-top: 20px
}

.xpm-scanner-input {
    margin: 0;
    width: auto !important;
}

.xpm-scanner-label {
    width: fit-content !important;
    padding: 20px 30px;
    background: var(--background-gradient-to-bottom);
    display: grid;
    grid-column: 1 / span 2;
    text-transform: uppercase;
    font-weight: bold;
}

.xpm-scanner-button {
    padding: 0;
    height: 28px;
}

@media only screen and (max-width: 786px) {
    
    .input-container{
        margin-left: 10px;
        width: calc(100% - 10px);
    }
    .input-container input, .input-container select, .input-container textarea{
        width: calc(100% - 15px);
    }
    .input-container{
        margin: inherit;
    }
}
