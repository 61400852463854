/* .print-button {
    background-color: #F39B2C;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    display: inline-block;
    margin-top: 2em;
    margin-left: 2em;
  }
.print-container {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align:center;
  align-content: center;
} */

/* ------------------------------------------------------------------------------------------------ */
/* ------ print */
.print-container {
  width: 500px;
  height: auto;
  margin: auto;
  padding: 20px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 100px;
  gap: 20px;
}

.print-container .print-input {
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.print-container .print-input-properties {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 0;
  border-radius: 25px;
  font-size: 16px;
}

.print-container .print-button {
  grid-column: span 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.print-container .print-button-properties {
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #F39B2C;
  border: none;
  font-size: 16px;
  color: white;
  text-align: center;
  text-decoration: none;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .print-container {
    width: 100%;
    padding: 20px;
    grid-template-columns: 1fr;
  }

  .print-container .print-button-properties {
    font-size: 16px !important;
  }
}
