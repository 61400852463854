.xpartpackage {
    grid-template-columns: 
        minmax(120px, 0.20fr) 
        minmax(120px, 0.20fr) 
        minmax(200px, 0.55fr) 
       10px;
}

.deliverylist {
    grid-template-columns: 
    minmax(200px, 0.20fr) 
    minmax(200px, 0.20fr) 
    minmax(200px, 0.55fr) 
}

.packagelist {
    grid-template-columns: 
        minmax(100px, 0.20fr) 
        minmax(100px, 0.20fr) 
        minmax(135px, 0.10fr) 
        minmax(135px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(150px, 0.10fr) 
        minmax(150px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(100px, 0.10fr) 
        minmax(100px, 0.10fr) 
}

.infotext {
    color:#000;
    text-align:center;
    margin:-30px 0 20px 0;
}

.list-grid-row {
    background-color: #515151;
}
#orderItemList{
    color: var(--main-grey);
}

#inputmain {
    width:100%;
}

.button-info {
    display:block;
    height:60px !important;
    width:350px;
    position:fixed;
    top:150px;
    right:-15px;
    margin-top: 15px;
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
    background: -moz-linear-gradient(top, #387060 0%, #387060 40%,#5dbba1 41%, #5dbba1 100%); 
    background: -moz-linear-gradient(top, #387060 0%, #387060 40%,#5dbba1 41%, #5dbba1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #387060 0%, #387060 40%,#5dbba1 41%, #5dbba1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #387060 0%, #387060 40%,#5dbba1 41%, #5dbba1 100%);
    z-index: 1999;
    transition: 0.2s;
}

.button-info:hover {
    background: -moz-linear-gradient(top, #5ca38f 0%, #5ca38f 40%,#c6ede2 41%, #c6ede2 100%); 
    background: -moz-linear-gradient(top, #5ca38f 0%, #5ca38f 40%,#c6ede2 41%, #c6ede2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #5ca38f 0%, #5ca38f 40%,#c6ede2 41%, #c6ede2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #5ca38f 0%, #5ca38f 40%,#c6ede2 41%, #c6ede2 100%);  
}

.button-info:hover > .button-info-line-bottom, .button-info-line-top {
    color:#515151 !important;     
}

.button-info-line-top, .button-info-line-bottom {
    display:block !important;
    color:white !important;
    transition: 0.2s;
}

.button-info-line-top {
    margin-top:-6px;
    font-size:14px;
}

.button-info-line-bottom {
    margin-top:10px;
}

.button-delivery {
    right:0px;
}

section.xpartform {
    margin-top: 10px;
    display:block;
    width:33%;
    max-width: 520px;
    padding: 20px;
    background-color: #000;
    border-radius:25px;
}

.xpartform > h1 {
    font-family: Roboto, Arial, sans-serif;
}

.list-container svg{
    color: var(--main-color);
    cursor: pointer;
}

.header-underline {
    height:3px;
    width: 200px;
    text-align: center;
    margin-bottom:30px;
    margin-top:-22px;
    border:none;
    background-image: linear-gradient(90deg, rgba(93,187,170,1) 20%, rgba(10,171,220,1) 39%, rgba(0,0,0,0) 40% ,rgba(0,0,0,0) 60%, rgba(10,171,220,1) 61% , rgba(60,114,184,1) 100%);
}

.header-underline-img {
    height: 44px;
    margin-top:-14px;
    text-align:center;
}

.xpm-warning-span{
    display: inline-block;
    color:var(--main-grey);
}

.info-display{
    display: block;
    color:var(--main-grey);
    font-weight: 700;
}

.info-display-list{
    margin-bottom: 20px;
    margin-top: 10px;
}

.xpartform h1 {
    color:white;
    text-transform: uppercase;
}

.xpart-list-grid {
    overflow-x: auto;
}

.multi-select{
    transition: all .3s;
}

.multiselect-selected{
    transform: scale(.99,.99);
    margin: 1px 0px;
    /* border: 2px solid green; */
    border: none;
    background-color:#888;
}

.optionalButtons .xpm-button:not(.confirmation-button){
    margin-left: 0px;
    width: 300px;
    margin-bottom: 10px;
}

.input-container {
    max-width:580px;
}

.infield, .infield .xpm-warnings {
    display:inline-block;
    width:33%;
    height:30px;
    text-align:left !important;
}

.infield .xpm-warnings {
    display:inline-block;
    width:90%;
    text-align:left !important;
    color:red;
}

.xpm-warnings{
    color: var(--main-grey);
    text-align:left;
}
#packagetype {
    height:20px;
 }

.infield .xpm-warnings-inactive{
  visibility: hidden;
}

.infield input, .infield select {
    width:90%;
    padding: 0 0 0 5px;
}

input {
    height:20px;
}

.infield label {
    margin:10px 0;
}

.field1 {
    width:100%;
}

label {
    color: var(--main-grey);
    font-weight:bold;
}

.field {
    display:inline-block;
    width:33%;
    margin-bottom: 20px;
}

.editfield1 {
    display:block;
}

.field label {
    display:inline-block;
    width:180px;
    font-size:14px !important;
}

.info-display {
    margin-bottom:20px;
    display:inline-block;
}

.info-header {
    font-weight:bold;
    text-align: center;
    margin-top:15px;
}

.packageedit {
    position:absolute;
    padding:10px 30px;
    display:inline-block;
    right:10px;
}

.modal .modal_content {
    padding:10px;
    font-size:14px;
}

.icon-properties {
    margin-right: 12px;
}

.delete-item-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.delete-item-content {
    grid-column: span 2;
    font-size: 120%;
    line-height: 170%;
    text-align: left;
}

.delete-item-button {
    grid-column: span 1;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-end;
    text-align: center;
}

.delete-item-button > .xpm-button {
    margin: auto;
    width: 220px;
}

.xpm-input {
    line-height:25px;
    min-height:25px;
}

.xpm-invalid {
    border: 2px solid red !important;
}

#packagetype.invalid {
    border: 2px solid red !important;
}

@media only screen and (max-width: 768px) {
    .xpart-list-grid-row-override {
        min-width: unset;

    }
    .section.xpartform {
        min-width: unset;
        display: grid;
    }

   
    .results--section{
        min-width: unset;
    }
}

@media only screen and (max-width: 600px) {
    .infield, .infield .xpm-warnings {
        width:100%;
    }

    .optionalButtons .xpm-button:not(.confirmation-button){
        margin-left: 5px;
        width: 190px;
        margin-bottom: 10px;
    }

    .infield .xpm-warnings {
        height:15px;
    }
}    


@media only screen and (max-width: 375px) {
    .xpart-list-grid-row-override {
        min-width: unset;
    }
    .optionalButtons .xpm-button:not(.confirmation-button){
        margin-left: 5px;
        width: 190px;
        margin-bottom: 10px;
    }

    
}
