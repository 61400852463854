.scan--section{
    color: black;
    font-weight: 700;
}

.scanner-container{
    width: 100%;
}

.input-container .scan-input {
    width: 100%;
    margin-bottom: 20px;
}

.scanner-container section{
    opacity: 1;
}

@keyframes flash {
    0% {opacity: 1}
    50% {opacity: 0}
    100% {opacity: 1}
  }

.scanner-container-flash section{
    animation-name: flash;
    animation-iteration-count: 1;
    animation-duration: .5s;
}

.xpm-btn-list{
    list-style: none;
    margin-left: -60px;
    margin-top: 30px;
}

.xpm-btn-list li{
    padding: 22px 0px;
}

.xpm-btn-list a{
    background-color: #f39b2c;
    border: none;
    color: #fff;
    padding: 15px 32px;
    text-decoration: none;
    font-size: 16px;
    margin-top: 2em;
    margin-left: 2em;
}

.gallery li{
    float:left;
    cursor: pointer;
}

.gallery li img:hover{
    opacity: 0.5;
}