/* Styles for pagination of tables */

.paginationControls a, .paginationControls a:hover, .paginationControls a:visited, .paginationText{
    color: var(--main-grey);
    padding: 5px;
    text-decoration: none;
}

.paginationControls div, .paginationControls div:hover, .paginationControls div:visited {
display: inline-block;
color: var(--main-grey);
padding: 5px;
text-decoration: none;
cursor: pointer;
}


#paginationCurrent{
    font-size: 20px;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.paginationControls{
    margin: auto;
    width: 140px;
    margin-bottom: 30px;
    background-color: var(--main-grey-bright);
    color: var(--main-grey);
}

