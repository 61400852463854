.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-container img{
    border: none
}

.loader-lock-overlay {
    z-index: var(--loader-spinner-overlay-z-index);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
}

.loader-lock-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--loader-spinner-z-index);
}

.loader-max-height {
    height: 100%;
}

.loader-max-width {
    width: 100%;
}

.loader-big {
    width: 300px;
    height: 300px;
}

.loader-medium {
    width: 100px;
    height: 100px;
}

.loader-small {
    width: 50px;
    height: 50px;
}