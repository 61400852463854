/* ------------------------------------------------------------------------------------------------ */
/* ------ container: scanner */
.scan-container {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 20px;
}

.scan-container-cam {
  grid-column: span 2;
}

.scan-container-title {
  grid-column: span 2;
  padding: 20px 30px;
  background: var(--background-gradient-to-bottom);
}

.scan-container-form {
  grid-column: span 1;
}

.scan-container-form-alternativ {
  grid-column: span 2;
}

.scan-container-form-alternativ > input {
  margin: 0;
}

.scan-container-button {
  grid-column: span 1;
}

.scan-container-button-alternative {
  grid-column: span 2;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ style: scanner */
.scan-container-flash {
  opacity: 1;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scan-container-flash-action {
  animation-name: flash;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

.scan-title-properties {
  color: var(--main-grey);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.scan-form-properties {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  background-color: #ffffff;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 16px;
}

.scan-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  cursor: pointer;
}

.scan-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .scan-container {
    width: 100%;
    padding: 20px 20px 20px 20px;
  }
}
