.xpm-button-container {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(163px, 1fr));
}

.xpm-button-container > * {
    margin: 20px 0 0 0;
    height: 45px;
    padding: 0 15px;
    box-sizing: border-box;
}

.xpm-container-top{
    margin-top:-80px;
}

.button-alignment {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.button-alignment-right {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.xpm-button {
    height: 45px;
}

@media only screen and (max-width: 375px) {
    .xpm-button-container {
        grid-template-columns: 1fr;
    }

    /* .optionalButtons {
        margin-top: -40px;
    } */

    .button-alignment {
        flex-direction: column;
    }   
    
    .xpm-button {
        width: 100% !important;
        font-size: 12px !important;
        padding: 10px;
    }

    /* .list-container {
        margin-top: 40px;
    } */
}
