/* ------------------------------------------------------------------------------------------------ */
/* ------ style: setting */
.setting-container {
  padding: 20px;
  box-sizing: border-box;
}
.setting-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-left-alignment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-full {
  grid-column: span 2;
  height: 100%;
}

.setting-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.form-title-left {
  font-weight: bold;
  color: var(--main-grey);
  text-transform: uppercase;
}

.form-input-properties {
  width: 100%;
  height: 25px;
  margin: 0;
  border: 1px solid gray;
}

.setting-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.setting-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

.form-warning {
  border-color: #ff0000;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .setting-form-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-left-alignment {
    align-items: center;
  }

  .form-full {
    grid-column: span 1;
  }
}
