.button-add-item{
    display: none;
}
#filterInput{
    width: 95%;
    min-width: 200px;
    max-width: 500px;
    left:20px;
}
.filter-list-item{
    cursor: default;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    color: black;
    background-color: var(--main-color-pale);
    border-radius: 16px;
    padding: 5px 10px;
    margin-right: 4px;
}

.filter-list-item li{
    list-style: none;
}

.search-bubble-delete{
    -webkit-appearance: none;
   -moz-appearance: none;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    max-height: 20px;
    max-width: 20px;
    outline: 0;
    position: relative;
    vertical-align: top;
    width: 10px;
    user-select: none;
    margin-left: 10px;
}

.filter-list{
    font-weight: normal;
    font-size: 12px !important;
    width: 95% !important;
    margin-left:20px !important;
    clear: left;
    cursor: text;
    list-style-type: none;
    min-height: 1px;
    overflow: hidden;
    outline: medium none;
    color: #1d1d1b !important;
    padding: 10px 0px !important;
    border: solid 0px #dedede;
    transition: all 0.5s ease;
}

.icon-remove{
color: var(--main-color);
cursor: pointer;
margin-top: 5px;
margin-right: 5px;
}

.search-container{
    margin-bottom: 10px;
    width: 25%;
    min-width: 220px;
    max-width: 500px;
    background-color: white;
    border-radius: 20px;
}

.filter-form{
    display: flex;
}

label, select, input{
    margin-top: 0px !important;
}

.specialFilter{
    position: absolute;
    width: 50%;
    min-width: 280px;
    max-width: 400px;
    display: block;
    left:20px;
    margin-top: 6px;
    z-index: 1900;
    color: gray;
    border: 6px solid rgba(255,255,255,0.7);
    background: rgba(255,255,255,0.7);
    transition: background 0.2s ease-in-out 0s;
    border-radius: 5px;
}

.specialFilter:hover{
    background: rgba(255,255,255,1);
}

#specialFilterBorder{
    border: 1px solid gray; 
    border-radius: 5px;  
}

.specialFilter ul{
    list-style: none;
}

.specialFilter li{
    margin: 0 0 10px -30px;
}

.specialFilter input, .specialFilter select{
    border-bottom: 1px solid gray;
    border-radius: 0px;
    width: 50%;
    margin-top: -6px;
    display: inline-block;
    position: absolute;
    background: rgba(255,255,255,0);
}

.specialFilter label{
    width: 40%;
    display: inline-block;
    font-size: 13.3333px;
}

@media only screen and (max-width: 783px) {
    .filter-list{
        margin-left:20px!important;
    }  
    .specialFilter {
        left:0px;
    } 
}