.xpm-link-btn a, .xpm-link-btn>span{
    width: 200px;
    height: 30px;
    display: block;
    color: white;
    padding: 10px;
    background-color: #f39b2c;
    text-align: center;
    cursor: pointer;
}

.xpm-link-btn a{
    color: white;
    text-decoration: none;
}

.xpm-link-btn a:visited{
    color: white;
}

.xpm-right{
    position: absolute;
    right: 20px;
    bottom: 20px;
}

#ServerGallery img{
    border: 2px solid var(--main-color);
}

#ServerGallery>div{
    float: left;
}

#ServerGallery>div>span{
    font-weight: 700;
    padding-left: 20px;
    display: block;
    margin-bottom: 25px;
}

.photo-gallery-img-delete {
    position: absolute;
    right: 0px;
    top:0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    color:#000;
    background-color: #FFF;
    padding: 0 9px;
    border-radius: 25px;
}

.photo-gallery-img-delete:hover {
    cursor: pointer;
}

.gallery > div {
    position:relative;
    display:inline-block;
}

.xpm-clear{
    clear: both;
}

.xpm-disabled span{
    background: grey;
}

.photo-gallery-button-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: space-between;
}

.gallery img{
    width: 550px;
}

@media only screen and (max-width: 768px) {
    .gallery img{
        width: 275px;
    }
    .xpm-camera-mode#page-wrap{
        padding-top: 0px !important;
    }
    .xpm-camera-mode.topheader{
        display: none !important;
    }
}
@media only screen and (max-width: 425px) {
    .gallery img{
        width: 100%;
    }
}