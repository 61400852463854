@import "./styles/global.css";
.topheader, .navbar{
    background-color: #000;
}
.currentUser{
    display: inline;
    position: absolute;
    top: 15px;
    right: 13px;
}
.topheader{
    position: fixed;
    width: 100%;
    z-index: 2000;
    height: 60px;
}
.topheader header {
    height: 100%;
}
.navbar{
    height: 100%;
}
.navbar-brand {
    height: 100%;
    width: 100%;
    position: relative;
}
.navbar-brand > a {
    position:absolute;
    left:50%;
    margin-left:-100px;
    line-height:54px;
}
img.navbar-item {
    position: relative;
    display:inline-block;
   /*margin-left: -100px;
    left: 50%;
   */
    width: 228px;
    min-width: 5%;
    vertical-align:middle;
}
h1, h2, h3 {
    color:black;
}
h1 {
    text-align: center;
    font-size: 30px;
}
#page-wrap{
    padding-top:60px;
}
/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    margin-right:5px;
}
/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}
/* Sidebar wrapper styles Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}
/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    height: 94% !important;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}
/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    box-sizing: border-box;
}
/* Individual item */
.bm-item {
    display: inline-block;
}
.bm-item, .bm-item:hover, .bm-item:visited{
    color: white;
    text-decoration: none;
    margin-bottom: 0.5em;
}
.bm-item span{
    margin-left: 10px;
}
/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    transition: none !important;
}
body{
    background-color: #F3F3F3;
    color: white;
}
span[title]{
    cursor: help;
}
.outer-container {
    display: grid;
    grid-template-rows: 1fr;
    min-height: 100vh;
}


.setting-style {
    display: grid;
    grid-template-columns: auto 1fr;
    text-decoration: none;
}

.email-properties,
.email-properties:active {
    grid-column: span 1;
    padding: 0 10px 0 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.user-icon-properties,
.user-icon-properties:active {
    grid-column: span 1;
    font-size: 30px;
    color: #ffffff;
}

@media only screen and (max-width: 768px) {
    img.navbar-item {
        position:relative;
        width: 25%;
        height:auto;
        min-width: 110px;
        margin-top:auto;
        margin-bottom:auto;
   }
   .email-properties {
       font-size: xx-small;
   }
}
