.order-list-grid-row-override{
    grid-template-columns: 
        minmax(125px, 200px) 
        minmax(120px, 200px) 
        minmax(105px, 250px) 
        minmax(105px, 250px) 
        minmax(110px, 150px) 
        minmax(200px, 350px) 
        minmax(120px, 200px) 
        minmax(200px, 220px) 
    }

#orderItemList{
    color: var(--main-grey);
}

.delivery-group-overview-list{
    grid-template-columns: 
    minmax(120px, 0.75fr) 
    minmax(150px, 0.5fr) 
    minmax(100px, 0.75fr) 
    minmax(100px, 00.75fr) 
    minmax(110px, 0.75fr)
    minmax(90px, 0.75fr)
    minmax(90px, 0.5fr) 
}

.list-xpart {
    grid-template-columns: 
        minmax(120px, 0.75fr) 
        minmax(150px, 0.5fr) 
        minmax(100px, 100px) 
        minmax(100px, 1.5fr)
        minmax(100px, 0.75fr) 
        minmax(100px, 00.75fr) 
        minmax(110px, 0.75fr)
        minmax(90px, 0.75fr)
        minmax(90px, 0.5fr) 
 
}

.list-xpart-evs-receive {
    grid-template-columns: 
        minmax(100px, 0.3fr)
        minmax(100px, 0.3fr)
        minmax(100px, 0.3fr)
}

.list-xpart-kme-assebly {
    grid-template-columns: 
        minmax(120px, 0.3fr) 
        minmax(120px, 0.3fr) 
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr) 
        minmax(120px, 0.3fr)
        minmax(20px, 0.1fr)
}

.list-xpart-kme-requested {
    grid-template-columns: 
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(20px, 0.1fr)
}

.list-grid-xpart-general-evs {
    grid-template-columns: 
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.3fr)
        minmax(120px, 0.1fr)
        minmax(120px, 0.1fr)
        minmax(20px, 0.1fr)
        minmax(20px, 0.1fr)
}

.list-xpart-assebly-commissions {
    grid-template-columns: 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
}


.list-xpart-supplier-kommissionieren {
    grid-template-columns: 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr) 
        minmax(150px, 0.2fr)
        minmax(150px, 0.2fr)
}


.list-package-supplier-picking {
    grid-template-columns: 
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr)
        minmax(110px, 0.2fr)
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr) 
        minmax(110px, 0.2fr)     
}


.list-xpart-qa {
    grid-template-columns: 
        90px
        minmax(100px, 146px)
        minmax(100px, 160px)
        minmax(100px, 120px)
        minmax(100px, 150px)
        minmax(100px, 260px)
        minmax(100px, 1fr)
        90px
        minmax(100px, 1fr)
        90px
}

.list-group-xpart {
    grid-template-columns: 
    minmax(100px, 100px) 
    minmax(100px, 1fr) 
    minmax(100px, 100px)
    minmax(100px, 100px)
    minmax(100px, 130px)
}

.xpart-into-delivery-group {
    grid-template-columns: 
    minmax(80px, 1fr) 
    minmax(80px, 1fr) 
    minmax(150px, 1fr)
    minmax(80px, 1fr)
    minmax(80px, 1fr)
    minmax(80px, 1fr)
}


.list-group-xpart-all {
    grid-template-columns: 
    minmax(100px, 1fr) 
    minmax(100px, 1fr) 
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
}

.list-group-xpart-check-table {
    grid-template-columns: 
    minmax(100px, 1fr) 
    minmax(100px, 1fr) 
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
}

.list-grid-item {
    text-overflow: ellipsis !important;
    overflow:hidden !important;
}

