.order-item-overview-list-grid-row-override{
    grid-template-columns:
        135px 
        minmax(100px, 1fr) 
        minmax(114px, 1fr) 
        72px 
        125px 
        150px 
        minmax(100px, 120px)
}

.add-xpart-button {
    width: 250px;
    margin: 10px 0;
}

.description-cell {
    grid-column: 1 / span 2;
    grid-template-columns: 1fr 3fr;
}
