/* Common styles for links, buttons, etc */

input, textarea, select{
    border-radius: 12px;
    border: none;
    min-height: 25px;
    box-sizing: border-box;
    padding: 0 10px;
}

/* Only Firefox */
@-moz-document url-prefix() {
    select {
        padding:5px 0 5px 5px;
    }
}

textarea{
    padding-top:2px;
}

input:focus, textarea:focus, select:focus {
    outline: none;    
}

.xpm-hidden{
    display: none !important;
}

.disabled-link {
    color: rgba(0,0,0,0);
    pointer-events: none;
    cursor: not-allowed;
}

.disabled-link svg{
    color: #555;
    color: rgba(0,0,0,0);
}

.xpm-button{
    text-align: center;
    background-color: var(--main-color);
    border-radius: 20px;
    border: none;
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    justify-content: center;
    transition: all 0.1s ease-in-out;
}

.xpm-button-right{
    right: 170px;
    position: absolute;
}

.xpm-button * {
    color: #fff;
    text-decoration: none;
}

.xpm-button:hover {
    background-color:var(--main-color-pale);
    color:var(--main-grey);
}

.xpm-disabled:hover {
    background-color:gray;    
    color:white;
}

.xpm-button :focus{
    outline: none;
}

.xpm-button-clear {
    background-color: black;
    border: 1px solid rgb(210, 9, 170);
}

.xpm-button-clear:hover {
    background-color: rgb(210, 9, 170);;
    color: var(--main-black);
}

.xpm-go-to-photos-button {
    width: 100%;
    height:45px;
    margin:20px 0;
    /*box-sizing: border-box;*/
}

.xpm-invalid {
    border-color: red;
}

.xpm-valid {
    border-color: green;
}

.xpm-warnings {
    font-size: 12px;
    text-align: right;
    height: 15px;
    overflow: hidden;
    transition: height .2s ease-in-out; 
    margin-top: 3px;
}

.xpm-warnings-inactive{
    height: 0px;
}

.save-button, .clear-button{
    margin-right: 0.5em;
}

.results--section {
    background-color: var(--main-grey-bright);
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
}

.soft-progress{
    cursor: progress;
}

.xpm-popup{
    position: absolute;
    display: block;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: 20px;
    width: 670px;
}

.xpm-popup-close{
    position: absolute;
    height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: var(--main-red);
}

.xpm-popup .historyRow span{
    min-width: 100px;
    margin-right: 10px;
    display: inline-block;
    width: 200px;
    padding: 5px;
}

.xpm-popup .historyRow span:nth-child(odd){
    background-color: var(--main-grey-bright)
}

.xpm-small-indicator{
    font-size: 8px;
    vertical-align: text-top;
}

.ProgressBar{
    padding: 14px;
}

.progress-total{
    width:  100%;
    background: var(--main-grey-bright);
    height:  20px;
    border-radius:  10px;
    overflow:  hidden;
}


.progress-loaded{
    background-color:  var(--main-color);
    width:  100%;
    height:  20px;
    border-radius:  5px;
    position:  relative;
    transition: transform .3s ease-in-out;
    transform: translateX(-100%);
}

.progressNumbers{
    text-align: right;
}

.file-upload{
    width: calc(100% - 28px);
    margin: 0px 14px;
}

.xpm-button-import[disabled]{
    background-color: var(--main-grey-bright);
    color: var(--main-grey)
}

.disabled-icon{
    color: #B8B8B8 !important;
    cursor: default !important;
}
.active-icon{
    color: rgb(93, 187, 161) !important;
    cursor: pointer !important;
}



@media only screen and (max-width: 786px) {
    .results--section{
        width: auto;
        padding: 0px;
        border-top: solid 1px var(--main-grey)  /* for collapsing margins fix */
    }

    .results--section>section {
        width: 100% !important;
    }
  
}
