/* ------------------------------------------------------------------------------------------------ */
/* ------ style: createuser */
.loginuser-container {
  padding: 20px;
  box-sizing: border-box;
}

.loginuser-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.form-left {
  grid-column: span 1;
  height: 100%;
}

.form-left-alignment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-right {
  grid-column: span 1;
  height: 100%;
}

.form-forgetpassword-alignment {
  height: 100%;
  padding: 0 30px;
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
}

.form-full {
  grid-column: span 2;
  height: 100%;
}

.loginuser-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.form-title-left {
  font-weight: bold;
  color: var(--main-grey);
  text-transform: uppercase;
}

.form-input-properties {
  width: 100%;
  height: 25px;
  margin: 0;
  border: 1px solid gray;
}

.form-select-properties {
  width: 100%;
  height: 30px;
  margin: 0;
  border: 1px solid gray;
}

.input-selection-container {
  max-height: 200px;
  overflow: auto;
}

.input-selection-properties {
  margin: 0;
  padding: 0 0 0 10px;
  list-style-type: none;
}

.input-selection-properties li {
  padding: 5px 0 5px 0;
  cursor: pointer;
}

.loginuser-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.loginuser-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}

.form-warning {
  border-color: #ff0000;
}

/* select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
} */

.selectbox-properties {
	position: relative;	
}

/* .selectbox-properties::before {
  width: 17px;
  height: 14px;
  padding-top: 3px;
  content: '\25B2';
  font-size: 20px;
  line-height: 8px;
  color: #000000;
  position: absolute;
  top: 6px;
  right: 12px;
  pointer-events: none;
  transform-origin: 50% 55%;
  transform: rotate(180deg);
} */

select::-ms-expand {
  display: none;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------ smartphone view */
@media only screen and (max-width: 375px) {
  .loginuser-form-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-left-alignment {
    align-items: center;
  }

  .form-full {
    grid-column: span 1;
  }
}
