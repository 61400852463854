/* ------------------------------------------------------------------------------------------------ */
/* ------ style: paswordforgotten */
.resetpassword-container {
  padding: 20px;
  box-sizing: border-box;
}

.resetpassword-container h1 {
  margin: 0;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  color: #ffffff;
  text-transform: uppercase;
}

.resetpassword-form-container {
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  background-color: #000000;
  border: 0;
  border-radius: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
}

.linktologinpage {
  color: var(--main-color);
  cursor: pointer;
}

.resetpassword-button {
  width: 100%;
}

.firemodal-overlay-container {
  width: 100%;
  height: auto;
}

.firemodal-overlay-container .content-container {
  padding: 0 20px 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firemodal-overlay-container .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firemodal-overlay-container .button-properties {
  width: 280px;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.firemodal-overlay-container .button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}