/*********** BEGIN CSS for SELECT NEW!!!!!! ***********/

.box select {
  background-color: #fff;
  border-radius: 12px;
  color: #fff;
  width: 280px;
  padding-left: 8px;
  /* height: 26px; */
  line-height: 14px;
  border: 1px solid gray;
  font-size: 14px;
  color: black;
  /* -webkit-appearance: button; */
  /* appearance: button; */
  outline: none;
  margin-top: -22px;
  z-index: 1000;
  margin-bottom: 15px;
}

/* .box::before {
  content: '\25B2';
  position: relative;
  display: inline-block;
  top: 6px;
  left: 250px;
  width: 25px;
  height: 27px;
  line-height: 18px;
  text-align: center;
  font-size: 20px;
  color: #000;
  pointer-events: none;
  transition: transform 0.1s ease-in-out;
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  border-radius: 12px;
  vertical-align: middle;
  z-index: 1;
  margin-bottom: 15px;
} */

.box:hover::before {
  border-radius: 12px;
  -moz-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  -o-transform: rotate(240deg);
  -webkit-transform: rotate(240deg);
}

.box select option {
  padding: 30px;
}

.box invalid {
  border: 3px solid red;
}

/*********** END CSS for SELECT NEW!!!!!! ************/

.formfields select:read-only,
.formfields input:read-only,
.formfields textarea:read-only {
  background-color:#d3d3d3;
}

.formfields label {
  display:inline-block!important;
  width:180px!important;
  margin-right:0px!important;
}

.formfields label,
.formfields select,
.formfields input,
.formfields textarea {
  margin-top: 20px;
  /* color:#333; */
  margin-bottom: 15px;
}

.formfields textarea {
  height:60px;
  line-height:18px;  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size:13px;
  
}

.input-container {
  max-width: 580px;
}

/* .formfields select {
  margin-left:-24px;
} */


h1 {
  letter-spacing: 2px;
}

.form-container {
  padding-top: 20px;
}

.form-button-properties {
  width: 100%;
  height: 45px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 25px;
}

.form-button-properties:hover {
  color: var(--main-grey);
  background-color: var(--main-color-pale);
}


section.xpartform {
  margin-top: 10px;
  display:block;
  width:33%;
  min-width: 560px;
  padding: 20px;
  background-color: #000;
  border-radius:25px;
}

.xpartform > h1 {
  font-family: Roboto, Arial, sans-serif;
}


.xpartform h1 {
  color:white;
  text-transform: uppercase;
}


@media only screen and (max-width: 768px) {
  .xpart-list-grid-row-override {
        min-width: unset;
    }
  section.xpartform {
      min-width:320px;
      border-radius: 0px;
  }
  .input-container input, .input-container select, .input-container textarea {
    width: calc(100% - 15px);
    margin: 0px;
  }
}