.content-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.header-button-alignment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.header-button {
  margin-left: 20px;
  padding: 10px 30px;
}

.edit-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.edit-field {
  padding: 10px 0 10px 0;
  font-size: 14px;
}

.edit-field label {
  width: 200px;
  display: inline-block;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column-1 {grid-column: span 1;}
.column-2 {grid-column: span 2;}
.column-3 {grid-column: span 3;}
.column-4 {grid-column: span 4;}
.column-5 {grid-column: span 5;}
.column-6 {grid-column: span 6;}

.button-container > .xpm-button {
  width: 300px;
  margin: 10px 0 10px 0;
}

.button-container > .xpm-hidden {
  display: none !important;
}

/* ------------------------------------------------- */
@media only screen and (max-width: 768px) {

}

/* ------------------------------------------------- */
@media only screen and (max-width: 375px) {
  .content-container {
    grid-template-columns: 1fr;
  }

  .edit-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .header-button-alignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .header-button {
    margin: 10px 0 10px 0;
    padding: 10px 30px;
  }

  .edit-field label {
    width: 150px;
    display: inline-block;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .column-xs-1 {grid-column: span 1;}
  .column-xs-2 {grid-column: span 2;}
  .column-xs-3 {grid-column: span 3;}
  .column-xs-4 {grid-column: span 4;}
  .column-xs-5 {grid-column: span 5;}
  .column-xs-6 {grid-column: span 6;}

  .button-container > .xpm-button {
    width: auto;
    margin: 10px 0 10px 0;
  }
}