body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Styles for datepicker and Bestellexport */

#date_from, #date_to {
  width:248px;   
  margin-left:32px;
}

span.header-underline-export {
  display:inline-block;
  height:20px;
  padding-top:10px;
}

.time_from_inaktiv, .time_to_inaktiv {
  background: rgba(200, 200, 200, 0.8);
  color:rgb(100,100,100);
  filter:blur(1.2px);
}

.infoLastDates {
  display: inline-block;
  width:148px;
}

#checkbox_time_from, #checkbox_time_to {
  -moz-transform:scale(1.5);
  transform:scale(1.5);
  height:15px;
  width:15px;
  margin-right:13px;
}

.form-export label{
  width:195px !important;
} 

@media only screen and (max-width: 700px) {
  .form-export input[type=text]{
    width:120px !important;
  } 
}

@media only screen and (max-width: 440px) {
  .form-export label {
    width:100px !important;
  }
  .form-export input[type=text]{
    width: 100px !important;
  }
}

/* "Helper-CSS (hide some Text for debug - we will remove later)"*/

/* hides text in file xparts.js, line 366 (<div id="orderItemList" data-help="debug log for orders and items. ignore."></div>) */
.xpm-go-to-photos-button + div#orderItemList {
  color:black;
}


@media only screen and (max-width: 700px) {
 .cameralayout{
    max-width: 80%;
    max-height: 80%;
  }
}

@media only screen and (min-width: 700px) {
  input:disabled {
    color:black;
    background-color: darkgray;
  }
  
  textarea:disabled {
    color:black;
    background-color: darkgray;
  }
  
  select:disabled {
    color:black;
    background-color: darkgray;
  }
}


/* END OF Helper-CSS */
