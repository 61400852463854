 .wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
  }
 
  .headerClass{
    width: "90%";
  }
  .extraButtons{
    width:300px; 
  }
  
  @media only screen and (max-width: 375px) {
    .wrapper {
      content: '';
      float: left;
      height: 150px;
      margin: 0 0 15px 15px;
      display: flex;
      flex-direction: column-reverse;
    }
    .buttons {
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
    }
    .headerClass{
      width: "90%";
    }
    .extraButtons{
      width:300px; 
    }
   
  }
  
  @media screen and (max-width:600px) {
    .wrapper {
      content: '';
      float: left;
      height: 150px;
      margin: 0 0 15px 15px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
    .buttons {
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
    }
    .headerClass{
      width: "90%";
    }
    .extraButtons{
      width:300px; 
    }
  }