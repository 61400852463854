.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 65px;
  width: 300px;
}

.notifications--hide {
    display: none;
}

.notifications__message--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: rgba(93, 187, 161, 0.96);
  padding: 0.5em;
  border-color: rgba(93, 187, 161, 0.96);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 5px;
}

.notifications__message--warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: rgba(255, 221, 87, 0.96);
  padding: 0.5em;
  border-color: rgba(255, 221, 87, 0.96);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 5px;
}

.notifications__message--error {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0.5em;
  background-color: rgba(241, 70, 104, 0.96);
  border-color: rgba(241, 70, 104, 0.96);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 5px;
}

.notifications__title {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  font-size: 1.414em;
  padding-top: 0.5em;
}

.notifications__content {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  font-size: 1em;
}
