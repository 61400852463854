.order-item-list-grid-row-override{
    grid-template-columns: 
        minmax(150px, 160px) 
        minmax(90px, 140px) 
        minmax(150px, 200px)
        minmax(100px, 1fr) 
        minmax(70px, 80px) 
        minmax(90px, 120px) 
        minmax(100px, 110px) 
        minmax(130px, 140px)
        minmax(110px, 120px)
}

.order-item-list-container{
    display: block;
    padding: 30px 0;
}

.order-item-list-container .xpm-button{ 
    margin: 30px 14px;
    height: 45px;
    padding: 0 15px;
    box-sizing: border-box;
}

.order-item-list-container svg{
    color: var(--main-color);
    cursor: pointer;
}

#orderItemList{
    color: var(--main-grey);
}

.function-column-space{
    margin-right: 10px;
}
.order-item-completion-status{
    display: flex !important;
    font-weight: bold;
    color:black;
}