@import "../Order/orderlist.css";

.excel-export {
    position:absolute;
    width:280px;
    height:40px;
    top:70px;
    right:30px;
    color:black;
}

.mapfunctioncolumn {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 12px;
}

.mapfunctioncolumn .column-1 {
    grid-column: span 1;
}

.not-active-element {
    color: #B8B8B8 !important;
    cursor: default !important;
}