:root {
    --main-color: #5dbba1;
    --main-color-pale: #C6EDE2;
    --secondary-color: #3c72b8;
    --main-grey: #515151;
    --main-grey-bright: #F1F1F2;
    --main-black: #1d1d1b;
    --main-red: #BA373E;
    
    --result-section-background-color: #333;
    
    --background-gradient-to-right: linear-gradient(to right, var(--main-color), var(--secondary-color));
    --background-gradient-to-bottom: linear-gradient(to bottom, var(--main-color), var(--secondary-color));

    --modal-overlay-z-index: 2999;
    --modal-window-z-index: 3000;
    --loader-spinner-overlay-z-index: 3001;
    --loader-spinner-z-index: 3002;
}
